import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { BsPencilFill, BsTrash } from "react-icons/bs";
import config from '../../config';
import Pagination from '../pagination';

const UsersList = () => {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage ,setUsersPerPage] = useState(5);
  const [totalUsers, setTotalUsers] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(`${config.apiHost}:${config.apiPort}/users-list`)
      .then(response => {
        setUsers(response.data);
        setTotalUsers(response.data.length);
      })
      .catch(error => {
        console.error('Error fetching users:', error);
      });
  }, []);

  // Pagination logic
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  const handleEdit = (userId) => {
    navigate(`/edit-user/${userId}`);
  };

  const handleDelete = (userId) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      axios.delete(`${config.apiHost}:${config.apiPort}/delete-user/${userId}`)
        .then(response => {
          setUsers(users.filter(user => user.id !== userId));
        })
        .catch(error => {
          console.error('Error deleting user:', error);
        });
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Redirect to Add User page
  const handleAddUser = () => {
    navigate('/add-user'); // Redirect to the Add User page
  };

  return (
    <div className="container mt-5">
      <div className='d-flex justify-content-between'>
        <h2>Users List</h2>
        <button className="btn btn-outline-dark" onClick={handleAddUser}>
          Add User
        </button>
      </div>

      {/* User Table */}
      <table className="table table-striped border">
        <thead>
          <tr>
            <th>ID</th>
            <th>Username</th>
            <th>Mobile</th>
            <th>Email</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentUsers.map(user => (
            <tr key={user.id}>
              <td>{user.id}</td>
              <td>{user.username || "N/A"}</td>
              <td>{user.mobile}</td>
              <td>{user.email}</td>
              <td>
                <BsPencilFill onClick={() => handleEdit(user.id)} style={{ cursor: 'pointer', marginRight: '15px', fontSize: '20px' }} />
                <BsTrash onClick={() => handleDelete(user.id)} style={{ cursor: 'pointer', fontSize: '20px', color: "red" }} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Pagination totalItems={totalUsers}
       itemsPerPage={usersPerPage}
        currentPage={currentPage}
         onPageChange={handlePageChange}
         setItemsPerPage ={setUsersPerPage}
          />
    </div>
  );
};

export default UsersList;
