import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa';

const MyProfile = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Retrieve user data from session storage
    const userData = sessionStorage.getItem('user');
    if (userData) {
      setUser(JSON.parse(userData));
      console.log(userData);
    } else {
      // If no user data is found, redirect to login
      navigate('/login');
    }
  }, [navigate]);

  const handleEditProfile = () => {
    navigate(`/update-profile/${user.id}`);  // Assuming you have a route for editing user profile
  };

  return (
    <div className="container mt-5">
      <h1 className="text-center">My Profile</h1>
      {user ? (
        <div className="card mx-auto mt-4" style={{ maxWidth: '600px', border: '1px solid #e0e0e0', borderRadius: '10px' }}>
          <div className="card-header bg-primary text-white d-flex justify-content-between align-items-center">
            <h5 className="mb-0">User Details</h5>
            <FaEdit onClick={handleEditProfile} style={{ cursor: 'pointer', fontSize: '1.5rem' }} />
          </div>
          <div className="card-body bg-light">
            <p className="card-text"><strong>Username:</strong> {user.username}</p>
            <p className="card-text"><strong>Email:</strong> {user.email}</p>
            <p className="card-text"><strong>Mobile:</strong> {user.mobile}</p>
          </div>
        </div>
      ) : (
        <p className="text-center">Loading...</p>
      )}
    </div>
  );
};

export default MyProfile;
