import React, { useEffect, useState } from "react";
import axios from "axios";
import { BsPencilFill, BsTrash } from "react-icons/bs";
import { Link } from "react-router-dom";
import config from "../../config";
import Pagination from "../pagination";

const EmailTemplatesList = () => {
  const [templates, setTemplates] = useState([]);
  const [users, setUsers] = useState([]); // To store the list of users
  const [currentPage, setCurrentPage] = useState(1);
  const [templatesPerPage, setTemplatesPerPage] = useState(5);
  const [totalTemplates, setTotalTemplates] = useState(0);

  // Fetch email templates and users
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch templates
        const templatesResponse = await axios.get(
          `${config.apiHost}:${config.apiPort}/templates`
        );
        setTemplates(templatesResponse.data);
        setTotalTemplates(templatesResponse.data.length);
        // Fetch users
        const usersResponse = await axios.get(
          `${config.apiHost}:${config.apiPort}/users-list` // Adjust the endpoint if needed
        );
        setUsers(usersResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Get templates for the current page
  const indexOfLastTemplate = currentPage * templatesPerPage;
  const indexOfFirstTemplate = indexOfLastTemplate - templatesPerPage;
  const currentTemplates = templates.slice(
    indexOfFirstTemplate,
    indexOfLastTemplate
  );

  // Map userId to username
  const getUsernameById = (userId) => {
    const user = users.find((user) => user.id === userId);
    return user ? user.username : "Unknown User"; // Default to "Unknown User" if not found
  };

  // Handle delete template
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this template?")) {
      try {
        await axios.delete(`${config.apiHost}:${config.apiPort}/templates/${id}`);
        console.log("Template deleted successfully");
        setTemplates(templates.filter((template) => template.id !== id));
        setTotalTemplates((prev) => prev - 1);
      } catch (error) {
        console.error("Error deleting template:", error);
      }
    }
  };

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="container-fluid pt-5">
      <div className="row">
        <div className="col-md-8 col-sm-12 main-container">
          <div className="container">
            <div className="d-flex justify-content-between">
              <h2>Email Templates</h2>
              <Link to="/add-template" className="btn btn-outline-dark mb-3">
                Add Template
              </Link>
            </div>
            <div className="table-responsive">
              <table className="table table-striped border">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Template Name</th>
                    <th>Template type</th>
                    <th>Username</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentTemplates.map((template) => (
                    <tr key={template.id}>
                      <td>{template.id}</td>
                      <td>{template.template_name}</td>
                      <td>{template.template_type}</td>
                      <td>{getUsernameById(template.user_id)}</td>
                      <td>
                        <Link to={`/edit-template/${template.id}`} className="me-3">
                          <BsPencilFill
                            style={{
                              cursor: "pointer",
                              color: "black",
                              fontSize: "20px",
                            }}
                          />
                        </Link>
                        <BsTrash
                          onClick={() => handleDelete(template.id)}
                          style={{
                            cursor: "pointer",
                            color: "red",
                            fontSize: "20px",
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Pagination Component */}
            <Pagination
              totalItems={totalTemplates}
              itemsPerPage={templatesPerPage}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              setItemsPerPage={setTemplatesPerPage}
            />
          </div>
        </div>
        <div className="col-md-4 col-sm-12 extra-container"></div>
      </div>
    </div>
  );
};

export default EmailTemplatesList;
