function Validation(values) {
    let error = {};
    const email_pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const password_pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d])[A-Za-z\d\S]{8,}$/;

    if (values.firstName === "") {
        error.firstName = "First Name should not be empty";
    } else {
        error.firstName = "";
    }

    if (values.lastName === "") {
        error.lastName = "Last Name should not be empty";
    } else {
        error.lastName = "";
    }

    if (values.mobile === "") {
        error.mobile = "Mobile Number should not be empty";
    } else {
        error.mobile = "";
    }

    if (values.email === "") {
        error.email = "Email should not be empty";
    } else if (!email_pattern.test(values.email)) {
        error.email = "Email didn't match";
    } else {
        error.email = "";
    }

    if (values.password === "") {
        error.password = "Password should not be empty";
    } else if (!password_pattern.test(values.password)) {
        error.password = "Password must be at least 8 characters long, and include at least one lowercase letter, one uppercase letter, one number, and one special character.";
    } else {
        error.password = "";
    }

    if (values.confirmPassword === "") {
        error.confirmPassword = "Confirm password should not be empty";
    } else if (values.password !== values.confirmPassword) {
        error.confirmPassword = "Passwords do not match";
    } else {
        error.confirmPassword = "";
    }

    return error;
}

export default Validation;
