import React, { useEffect, useState } from "react";
import axios from "axios";
import { BsPencilFill, BsTrash } from "react-icons/bs";
import { useNavigate, Link } from "react-router-dom";
import config from "../../config";
import Pagination from "../pagination";

const LeadList = () => {
  const [leads, setLeads] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [leadsPerPage, setLeadsPerPage] = useState(5); // Initial default, will be updated from Pagination
  const [totalLeads, setTotalLeads] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${config.apiHost}:${config.apiPort}/leads`)
      .then((response) => {
        setLeads(response.data);
        setTotalLeads(response.data.length);
      })
      .catch((error) => {
        console.error("Error fetching leads:", error);
      });
  }, []);

  // Get current leads for the current page
  const indexOfLastLead = currentPage * leadsPerPage;
  const indexOfFirstLead = indexOfLastLead - leadsPerPage;
  const currentLeads = leads.slice(indexOfFirstLead, indexOfLastLead);

  const handleEdit = (id) => {
    navigate(`/edit-lead/${id}`);
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this lead?")) {
      axios
        .delete(`${config.apiHost}:${config.apiPort}/delete-lead/${id}`)
        .then((response) => {
          setLeads(leads.filter((lead) => lead.id !== id));
        })
        .catch((error) => {
          console.error("Error deleting lead:", error);
        });
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="container-fluid pt-5">
      <div className="row">
        <div className="col-8 main-container">
          <div className="container">
            <div className="d-flex justify-content-between">
              <h2>Leads List</h2>
              <Link to="/add-lead" className="btn btn-outline-dark mb-3">
                Add Lead
              </Link>
            </div>
            <div className="table-responsive">
              <table className="table table-striped border">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Message</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentLeads.map((lead) => (
                    <tr key={lead.id}>
                      <td>{lead.id}</td>
                      <td>{lead.name}</td>
                      <td>{lead.email}</td>
                      <td>{lead.phone}</td>
                      <td>{lead.message}</td>
                      <td>
                        <BsPencilFill
                          onClick={() => handleEdit(lead.id)}
                          style={{
                            cursor: "pointer",
                            fontSize: "20px",
                            marginRight: "15px",
                          }}
                        />
                        <BsTrash
                          onClick={() => handleDelete(lead.id)}
                          style={{
                            cursor: "pointer",
                            color: "red",
                            fontSize: "20px",
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Pagination Component */}
            <Pagination
              totalItems={totalLeads}
              itemsPerPage={leadsPerPage}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              setItemsPerPage={setLeadsPerPage} //Pass the setter to update leadsPerPage
            />
          </div>
        </div>
        <div className="col-4 extra-container"></div>
      </div>
    </div>
  );
};

export default LeadList;
