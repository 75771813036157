import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Validation from '../../validations/signupValidation';
import axios from 'axios';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import config from '../../config';

const Signup = () => {
    const [values, setValues] = useState({
        username: "",
        firstName: "",
        lastName: "",
        mobile: "",
        email: "",
        password: "",
        confirmPassword: ""
    });

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [agreeToTerms, setAgreeToTerms] = useState(false);
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [toast, setToast] = useState({ show: false, message: "", type: "" });  // Added toast state

    const handleInput = (event) => {
        const { name, value } = event.target;
        setValues((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const err = Validation(values);
        setErrors(err);
    
        if (Object.values(err).every((error) => error === "")) {
            setLoading(true);
    
            const payload = {
                username: values.username,
                first_name: values.firstName,
                last_name: values.lastName,
                mobile: values.mobile,
                email: values.email,
                password: values.password
            };
    
            axios.post(`${config.apiHost}:${config.apiPort}/signup`, payload)
                .then((res) => {
                    setLoading(false);
    
                    if (res.data.status === "Success") {
                        setToast({ show: true, message: "Account created successfully!", type: "success" });
                        setTimeout(() => {
                            setToast({ show: false });
                            navigate('/');
                        }, 2000);
                    } else if (res.data.status === "Error" && res.data.message === "Email or Username already exists") {
                        setToast({ show: true, message: "Email or Username already exists. Please use a different email.", type: "danger" });
                        setTimeout(() => setToast({ show: false }), 3000);
                    } else {
                        setToast({ show: true, message: "Signup failed. Please try again.", type: "danger" });
                        setTimeout(() => setToast({ show: false }), 3000);
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    setToast({ show: true, message: "Signup failed. Please try again.", type: "danger" });
                    setTimeout(() => setToast({ show: false }), 3000);
                });
        }
    };
    
    
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return (
        <div className='d-flex justify-content-center align-items-center bg-info pt-5'>
            <div className='bg-white p-4 rounded-3 shadow-sm' style={{ maxWidth: '500px', width: "90%" }}>
                <h2 className='text-center mb-4'>Sign Up</h2>
                <form onSubmit={handleSubmit}>
                <div className='mb-3'>
                <label htmlFor='username' className='form-label'><b>Username</b></label>
                <input
                    type='text'
                    placeholder='Enter Username'
                    name='username'
                    value={values.username}
                    onChange={handleInput}
                    className='form-control rounded-3'
                    disabled={loading}
                />
                {errors.username && (<span className="text-danger">{errors.username}</span>)}
            </div>
                    <div className='mb-3'>
                        <label htmlFor='firstName' className='form-label'><b>First Name</b></label>
                        <input
                            type='text'
                            placeholder='Enter First Name'
                            name='firstName'
                            value={values.firstName}  // Controlled input
                            onChange={handleInput}
                            className='form-control rounded-3'
                            disabled={loading}
                        />
                        {errors.firstName && (<span className="text-danger">{errors.firstName}</span>)}
                    </div>
                    <div className='mb-3'>
                        <label htmlFor='lastName' className='form-label'><b>Last Name</b></label>
                        <input
                            type='text'
                            placeholder='Enter Last Name'
                            name='lastName'
                            value={values.lastName}  // Controlled input
                            onChange={handleInput}
                            className='form-control rounded-3'
                            disabled={loading}
                        />
                        {errors.lastName && (<span className="text-danger">{errors.lastName}</span>)}
                    </div>
                    <div className='mb-3'>
                        <label htmlFor='mobile' className='form-label'><b>Mobile Number</b></label>
                        <input
                            type='text'
                            placeholder='Enter Mobile Number'
                            name='mobile'
                            value={values.mobile}  // Controlled input
                            onChange={handleInput}
                            className='form-control rounded-3'
                            disabled={loading}
                        />
                        {errors.mobile && (<span className="text-danger">{errors.mobile}</span>)}
                    </div>
                    <div className='mb-3'>
                        <label htmlFor='email' className='form-label'><b>Email</b></label>
                        <input
                            type='email'
                            placeholder='Enter Email'
                            name="email"
                            value={values.email}  // Controlled input
                            onChange={handleInput}
                            className='form-control rounded-3'
                            disabled={loading}
                        />
                        {errors.email && (<span className="text-danger">{errors.email}</span>)}
                    </div>
                    <div className='mb-3'>
                        <label htmlFor='password' className='form-label'><b>Password</b></label>
                        <div className="input-group">
                            <input
                                type={showPassword ? 'text' : 'password'}
                                placeholder='Enter Password'
                                name='password'
                                value={values.password}  // Controlled input
                                onChange={handleInput}
                                className='form-control rounded-3'
                                disabled={loading}
                            />
                            <span className="input-group-text" onClick={toggleShowPassword}>
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </span>
                        </div>
                        {errors.password && (<span className="text-danger">{errors.password}</span>)}
                    </div>
                    <div className='mb-3'>
                        <label htmlFor='confirmPassword' className='form-label'><b>Confirm Password</b></label>
                        <div className="input-group">
                            <input
                                type={showConfirmPassword ? 'text' : 'password'}
                                placeholder='Enter Confirm Password'
                                name='confirmPassword'
                                value={values.confirmPassword}  // Controlled input
                                onChange={handleInput}
                                className='form-control rounded-3'
                                disabled={loading}
                            />
                            <span className="input-group-text" onClick={toggleShowConfirmPassword}>
                                {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                            </span>
                        </div>
                        {errors.confirmPassword && (<span className="text-danger">{errors.confirmPassword}</span>)}
                    </div>
                    <div className='mb-3 form-check'>
                        <input
                            type='checkbox'
                            className='form-check-input'
                            id='agreeToTerms'
                            checked={agreeToTerms}
                            onChange={() => setAgreeToTerms(!agreeToTerms)}
                        />
                        <label className='form-check-label' htmlFor='agreeToTerms'>
                            I agree to the terms and policies
                        </label>
                    </div>
                    <button 
                        type="submit" 
                        className='btn btn-success w-100 rounded-3'  
                        disabled={!agreeToTerms || loading} // Corrected the disabled condition
                    >
                        {loading ? "Please wait..." : "Sign Up"}
                    </button>
                    <Link to="/" className='text-decoration-none mt-3 d-block text-center'>I already have an account</Link>
                </form>
            </div>

            {/* Toast Notification */}
            {toast.show && (
                <div className={`toast-container position-fixed start-50 translate-middle p-3`} style={{ zIndex: 5 , top: 550 }}>
                    <div className={`toast show bg-${toast.type} text-white`} role="alert">
                        <div className="toast-body">{toast.message}</div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Signup;
