import React, { useContext } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/winspark.in_.png";
import AuthContext from "../contexts/AuthContext";
import { AiFillHome } from "react-icons/ai";

const Header = () => {
  const { logout, role } = useContext(AuthContext);

  // Function to handle logout with confirmation alert
  const handleLogout = () => {
    if (window.confirm("Are you sure you want to logout?")) {
      logout();
    }
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-dark">
      <div className="container-fluid">
        <Link to="/home">
          <img
            src={logo}
            alt="winspark-logo"
            style={{ height: "40px", marginRight: "10px", cursor: "pointer" }}
          />
        </Link>
        <button
          className="navbar-toggler bg-white"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <Link
                className="nav-link text-white"
                to="/home"
                style={{ marginTop: "-2px" }}
              >
                <AiFillHome style={{ fontSize: "20px" }} />
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-white" to="/index">
                Leads
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-white" to="/channels-list">
                Channels
              </Link>
            </li>
            {role === "admin" && (
              <li className="nav-item">
                <Link className="nav-link text-white" to="/users">
                  Users
                </Link>
              </li>
            )}
            {role === "admin" && (
              <li className="nav-item">
                <Link className="nav-link text-white" to="/inbox">
                  Inbox
                </Link>
              </li>
            )}
            {role === "admin" && (
              <li className="nav-item">
                <Link className="nav-link text-white" to="/profile">
                  Profiles
                </Link>
              </li>
            )}
            <li className="nav-item">
              <Link className="nav-link text-white" to="/posts">
                Posts
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link text-white"
                to="/chatuserchannels/add/facebook"
              >
                Facebook
              </Link>
            </li>

            <li className="nav-item">
              <Link className="nav-link text-white" to="/chatuserchannels">
                Chatuserchannels
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-white" to="/forms-list">
                Form
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-white" to="/email-editor">
                EmailEditor
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-white" to="/campaigns">
                Campaigns
              </Link>
            </li>
            <li className="nav-item dropdown">
              <button
                className="btn btn-link nav-link dropdown-toggle text-white"
                type="button"
                id="navbarDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Account
              </button>
              <ul
                className="dropdown-menu"
                style={{ left: "-75px" }}
                aria-labelledby="navbarDropdown"
              >
                <li>
                  <Link className="dropdown-item" to="/change-password">
                    Change Password
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/myprofile">
                    My Profile
                  </Link>
                </li>
                <li>
                  <button className="dropdown-item" onClick={handleLogout}>
                    Logout
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
