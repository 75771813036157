import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SurveyCreator, SurveyCreatorComponent } from "survey-creator-react";
import axios from "axios"; // For API calls
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";
import config from "../../config";

const FormBuilder = () => {
  const [creator, setCreator] = useState(null);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const storedUser = sessionStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    } else {
      alert("You must be logged in to access this page.");
      navigate("/login"); // Redirect to login if no user is stored
    }

    // Initialize Survey Creator
    const surveyCreator = new SurveyCreator({
      showLogicTab: true,
      isAutoSave: false,
    });
    setCreator(surveyCreator);

    // Cleanup on unmount
    return () => {
      if (surveyCreator) surveyCreator.dispose();
    };
  }, [navigate]);

  const handleSaveToBackend = async () => {
    if (!creator) {
      alert("Survey Creator is not initialized.");
      return;
    }

    if (!user) {
      alert("User information is missing. Please log in again.");
      return;
    }

    const form_name = prompt("Enter a name for this form:");
    if (!form_name || form_name.trim() === "") {
      alert("Form name cannot be empty!");
      return;
    }

    const user_id = user.id; // Get the logged-in user's ID
    const form_datas = JSON.stringify(creator.JSON);

    try {
      const response = await axios.post(`${config.apiHost}:${config.apiPort}/custom-forms/add-form`, {
        form_name,
        user_id,
        form_datas,
      });

      if (response.status === 201) {
        alert("Form saved successfully!");
        navigate(`/forms-list/`);
      } else {
        alert("Failed to save the form. Please try again.");
      }
    } catch (error) {
      console.error("Error saving form:", error);
      alert("An error occurred while saving the form.");
    }
  };

  return (
    <div className="p-4">
      <div className="d-flex justify-content-between p-2">
        <div>
          <h2>Form Builder</h2>
        </div>
        <div>
          <button onClick={handleSaveToBackend} className="btn btn-outline-success fw-bold">
            Save Form
          </button>
        </div>
      </div>
      {creator && <SurveyCreatorComponent creator={creator} />}
    </div>
  );
};

export default FormBuilder;
 