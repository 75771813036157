import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../../config';

const Channel = () => {
  const [channels, setChannels] = useState([]);

  // Mapping channel names to their respective image URLs and links
  const channelData = {
    whatsapp: {
      image: 'https://img.icons8.com/fluency/48/000000/whatsapp.png',
      link: 'https://web.whatsapp.com/',
    },
    telegram: {
      image: 'https://img.icons8.com/fluency/48/000000/telegram-app.png',
      link: 'https://telegram.org/',
    },
    instagram: {
      image: 'https://img.icons8.com/fluency/48/000000/instagram-new.png',
      link: 'https://www.instagram.com/',
    },
    facebook: {
      image: 'https://img.icons8.com/fluency/48/000000/facebook.png',
      link: 'https://www.facebook.com/',
    },
    twitter: {
      image: 'https://img.icons8.com/fluency/48/000000/twitter.png',
      link: 'https://twitter.com/',
    },
  };

  useEffect(() => {
    const fetchChannels = async () => {
      try {
        const response = await axios.get(`${config.apiHost}:${config.apiPort}/channels`);
        setChannels(response.data);
      } catch (error) {
        console.error('Error fetching channels:', error);
      }
    };

    fetchChannels();
  }, []);

  return (
    <div className="container mt-5">
      <h3 className="text-center mb-4">Choose a channel</h3>
      <p className="text-center">
        To start our chat, please configure the communication channel by providing the context or topic you’d like to discuss. Once that's set, I can assist you better and address your questions or tasks.
      </p>

      <div className="row g-3 mt-4">
        {channels.map((channel) => (
          <div className="col-6 col-md-4 col-lg-3" key={channel.id}>
            <a href={channelData[channel.name.toLowerCase()]?.link || '#'} target="_blank" rel="noopener noreferrer" className='channel-link'>
            <div className="channel-card text-center bg-white">
              
              <img
                src={channelData[channel.name.toLowerCase()]?.image || 'https://img.icons8.com/fluency/48/000000/api-settings.png'}
                alt={channel.name}
                className="img-fluid"
              />
            
            <p className="channel-title">{channel.name}</p>
          </div>
            </a>
             
          </div>
        ))}
      </div>
    </div>
  );
};

export default Channel;
