import React, { useState, useEffect, useContext } from "react";
import { Carousel, Button } from "react-bootstrap";
import axios from "axios";
import { BsCheckCircle } from "react-icons/bs"; // Import green tick icon
import config from "../../config";
import AuthContext from "../../contexts/AuthContext";
import SwitchCheckbox from "./switchCheckbox";
import InputField from "./InputField"; // Importing the reusable input component

const PostSlider = () => {
  const { user } = useContext(AuthContext);
  const [postData, setPostData] = useState({
    type: "text",
    message: "",
    user_id: user.id,
    media_url: "",
    video_file: {},
    selectedChannels: [],
  });

  const [channels, setChannels] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loadingAnimation, setLoadingAnimation] = useState(false);

  const [toast, setToast] = useState({
    show: false,
    message: "",
    type: "success",
  });

  useEffect(() => {
    const fetchChannels = async () => {
      try {
        const response = await axios.get(
          `${config.apiHost}:${config.apiPort}/channels`
        );
        setChannels(response.data);
      } catch (error) {
        console.error("Error fetching channels:", error);
      }
    };
    fetchChannels();
  }, []);

  const handleTypeChange = (type) => {
    setPostData({ ...postData, type, media_url: "", video_file: "" });
    setSelectedFile(null);
    setImageUrl("");
    goToNextSlide();
  };

  const handleMessageChange = (e) => {
    setPostData({ ...postData, message: e.target.value });
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const allowedTypes = ["image/jpeg", "image/png", "video/mp4"];
    if (!allowedTypes.includes(file.type)) {
      alert("Unsupported file type. Please upload an image or MP4 video.");
      return;
    }
    if (file.size > 5 * 1024 * 1024) {
      alert("File size exceeds 5MB.");
      return;
    }

    setSelectedFile(file);

    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await axios.post(
        `${config.apiHost}:${config.apiPort}/posts/upload`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      setImageUrl(response.data.url);
      setPostData((prev) => ({
        ...prev,
        media_url: response.data.url,
        video_file: file,
      }));
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    setPostData((prevData) => {
      const updatedChannels = checked
        ? [...prevData.selectedChannels, value]
        : prevData.selectedChannels.filter((id) => id !== value);
      return { ...prevData, selectedChannels: updatedChannels };
    });
  };

  const goToNextSlide = () => {
    setActiveIndex((prevIndex) => Math.min(prevIndex + 1, 2));
  };

  const goToPreviousSlide = () => {
    setActiveIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleSubmit = async () => {
    if (!postData.message && !postData.media_url) {
      alert("Please add a message or upload media.");
      return;
    }
    if (postData.selectedChannels.length === 0) {
      alert("Please select at least one channel.");
      return;
    }

    setLoading(true);
    setIsSubmitted(true); // Show progress bar only after submission
    setLoadingAnimation(true); // Start the animation
    try {
      const totalChannels = postData.selectedChannels.length;
      let completedChannels = 0;

      for (const channelId of postData.selectedChannels) {
        await axios.post(`${config.apiHost}:${config.apiPort}/posts/add`, {
          ...postData,
          channel_id: channelId,
        });
        completedChannels++;
        setUploadProgress({
          percent: (completedChannels / totalChannels) * 100,
          label: `${completedChannels}/${totalChannels}`,
        });
      }

      setToast({
        show: true,
        message: "Post submitted successfully!",
        type: "success",
      });
      setUploadProgress({
        percent: 100,
        label: `${totalChannels}/${totalChannels}`,
      });
      setLoading(false);

      // Delay the tick icon to allow animation
      setTimeout(() => {
        setLoadingAnimation(false); // End the animation
      }, 2000);
    } catch (error) {
      console.error("Error adding post:", error);
      setToast({
        show: true,
        message: "Failed to submit post.",
        type: "error",
      });
      setLoading(false);
      setLoadingAnimation(false); // End the animation
    }
  };

  return (
    <div className="container-fluid post-container">
      <div className="row">
      <div className={`col-8 main-container ${isSubmitted ? "disabled" : ""}`}>
          {/* Toast Message */}
          {toast.show && (
            <div
              className={`toast-container position-fixed start-50 translate-middle p-3`}
              style={{ zIndex: 5, top: 550 }}
            >
              <div
                className={`toast show bg-${toast.type} text-white`}
                role="alert"
              >
                <div className="toast-body">{toast.message}</div>
              </div>
            </div>
          )}

          <div
            className={`d-flex justify-content-between post-navigation-buttons ${
              activeIndex === 0 ? "justify-content-end" : ""
            }`}
          >
            {activeIndex > 0 && (
              <Button variant="outline-dark" onClick={goToPreviousSlide}>
                Previous
              </Button>
            )}
            {activeIndex < 2 && (
              <Button variant="outline-dark" onClick={goToNextSlide}>
                Next
              </Button>
            )}
          </div>
          <Carousel
            activeIndex={activeIndex}
            controls={false}
            indicators={false}
            interval={null}
          >
            {/* First Tab: Post Type Selection */}
            <Carousel.Item>
              <div className="d-flex flex-column align-items-center mt-3">
                <h3>Select Post Type</h3>
                <div className="d-flex justify-content-around mt-4">
                  {["text", "image", "video"].map((type) => (
                    <div
                      key={type}
                      className={`post-type-box ${
                        postData.type === type ? "" : ""
                      }`}
                      onClick={() => handleTypeChange(type)}
                    >
                      {type.charAt(0).toUpperCase() + type.slice(1)}
                    </div>
                  ))}
                </div>
              </div>
            </Carousel.Item>

            {/* Second Tab: Content and File Upload */}
            <Carousel.Item>
              <div className="d-flex flex-column align-items-center mt-3">
                <h3>
                  {postData.type.charAt(0).toUpperCase() +
                    postData.type.slice(1)}{" "}
                  Post Details
                </h3>
                <InputField
                  label="Message"
                  as="textarea"
                  rows={3}
                  placeholder="Write your message here..."
                  value={postData.message}
                  onChange={handleMessageChange}
                />
                {(postData.type === "image" || postData.type === "video") && (
                  <InputField
                    type="file"
                    label="Upload File"
                    onChange={handleFileChange}
                  />
                )}
                {imageUrl && (
                  <img
                    src={imageUrl}
                    alt="Uploaded"
                    style={{ maxWidth: "300px", marginTop: "20px" }}
                  />
                )}
              </div>
            </Carousel.Item>

            {/* Third Tab: Channel Selection */}
            <Carousel.Item>
              <div className="d-flex flex-column align-items-center mt-3">
                <h3>Select Channels</h3>
                <div className="channel-grid">
                  {channels.map((channel) => (
                    <SwitchCheckbox
                      key={channel.id}
                      value={channel.id}
                      label={channel.name}
                      onChange={handleCheckboxChange}
                    />
                  ))}
                </div>
                <Button
                  variant="outline-success"
                  className="mt-3"
                  onClick={handleSubmit}
                >
                  {loading ? "Submitting..." : "Submit"}
                </Button>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
        <div className="col-4 extra-container p-3">
          <h5 className="text-center pt-3">Preview</h5>

          <div className="m-4">
            {isSubmitted && ( // Only show the progress bar after submission
              <div>
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: `${uploadProgress.percent || 0}%` }}
                    aria-valuenow={uploadProgress.percent || 0}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div className="text-center mt-2">
                  {uploadProgress.label}{" "}
                  {/* Display the progress label like 2/3 */}
                </div>
              </div>
            )}
          </div>

          <div className="preview-container p-3">
            {isSubmitted ? (
              <div className="d-flex justify-content-center align-items-center">
                {loadingAnimation ? (
                  <div className="spinner-border text-success" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <BsCheckCircle size={100} className="text-success" />
                )}
              </div>
            ) : (
              <>
                {postData.message && (
                  <p className="preview-text-post">{postData.message}</p>
                )}
                {postData.type === "image" && imageUrl && (
                  <img
                    src={imageUrl}
                    alt="Uploaded Preview"
                    style={{ maxWidth: "100%" }}
                  />
                )}
                {postData.type === "video" && imageUrl && (
                  <video controls style={{ maxWidth: "100%" }}>
                    <source src={imageUrl} type="video/mp4" />
                  </video>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostSlider;
