// import React, { useState } from 'react';
// import axios from 'axios';
import { Link } from "react-router-dom";

const ChatUserChannels = () => {
  // const [channelsData, setChannelsData] = useState([]);

  // useEffect(() => {
  //   // Fetch chat user channels data
  //   axios.get('/chatuserchannels')
  //     .then(response => {
  //       setChannelsData(response.data);
  //     })
  //     .catch(error => {
  //       console.error('Error fetching chat user channels:', error);
  //     });
  // }, []);

  return (
    <div className="container-fluid pt-5">
      <div className="row">
        <div className="col-8 main-container">
          <div className="container">
            <div className="d-flex justify-content-between">
              <h3 className="text-center mb-4">Chat User Channels</h3>
              <Link
                to="/chatuserchannels/add"
                className="btn btn-outline-dark mb-3"
              >
                Add
              </Link>
            </div>
            <div className="table-responsive">
              <table className="table table-striped border">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Channel ID</th>
                    <th>Recipient ID</th>
                    <th>Data</th>
                    <th>Date/Time</th>
                    <th>User ID</th>
                  </tr>
                </thead>
                {/* <tbody>
          {channelsData.length > 0 ? (
            channelsData.map((channel) => (
              <tr key={channel.id}>
                <td>{channel.id}</td>
                <td>{channel.channel_id}</td>
                <td>{channel.recipient_id}</td>
                <td>{channel.datas}</td>
                <td>{channel.datetime}</td>
                <td>{channel.user_id}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" className="text-center">No data found</td>
            </tr>
          )}
        </tbody> */}
              </table>
            </div>
          </div>
        </div>
        <div className="col-4 extra-container"></div>
      </div>
    </div>
  );
};

export default ChatUserChannels;
