import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import config from '../../config';

const EditProfile = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  
  // State for profile data
  const [profile, setProfile] = useState({
    name: "",
    mobile: "",
    email: "",
  });
  
  // State for managing toast notifications
  const [toast, setToast] = useState({ show: false, message: '', type: '' });

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const { data } = await axios.get(`${config.apiHost}:${config.apiPort}/profiles/${id}`);
        setProfile(data);
      } catch (error) {
        console.error("Error fetching profile:", error);
        setToast({
          show: true,
          message: 'Failed to load profile!',
          type: 'danger',
        });
        setTimeout(() => setToast({ show: false }), 3000);
      }
    };

    fetchProfile();
  }, [id]);

  const handleChange = (e) => {
    setProfile({ ...profile, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${config.apiHost}:${config.apiPort}/profiles/${id}`, profile);
      
      // Show success toast
      setToast({
        show: true,
        message: 'Profile updated successfully!',
        type: 'success',
      });
      
      // Hide toast and redirect after 3 seconds
      setTimeout(() => {
        setToast({ show: false });
        navigate('/profile');
      }, 3000);

    } catch (error) {
      console.error("Error updating profile:", error);
      
      // Show error toast
      setToast({
        show: true,
        message: 'Failed to update profile!',
        type: 'danger',
      });
      
      // Hide error toast after 3 seconds
      setTimeout(() => setToast({ show: false }), 3000);
    }
  };

  return (
    <div className="container mt-5">
      <h2>Edit Profile</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label className="form-label">Name</label>
          <input
            type="text"
            className="form-control"
            name="name"
            value={profile.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Mobile</label> {/* Changed from Phone to Mobile */}
          <input
            type="text"
            className="form-control"
            name="mobile"  // Changed 'phone' to 'mobile'
            value={profile.mobile}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Email</label>
          <input
            type="email"
            className="form-control"
            name="email"
            value={profile.email}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" className="btn btn-outline-dark">Update Profile</button>
      </form>

      {/* Toast Notification */}
      {toast.show && (
        <div className={`toast-container position-fixed start-50 translate-middle p-3`} style={{ zIndex: 5, top: 550 }}>
          <div className={`toast show bg-${toast.type} text-white`} role="alert">
            <div className="toast-body">{toast.message}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditProfile;
