import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../config";
import FacebookLogin from "react-facebook-login";

function Facebook() {
  const [channel_id, setChannelId] = useState(null);
  const [toast, setToast] = useState({ show: false, message: "", type: "" });

  useEffect(() => {
    axios
      .get(`${config.apiHost}:${config.apiPort}/channels`)
      .then((response) => {
        const facebookChannel = response.data.find(
          (channel) => channel.name === "facebook"
        );
        if (facebookChannel) {
          setChannelId(facebookChannel.id);
        } else {
          console.log("Facebook channel not found");
        }
      })
      .catch((error) => {
        console.error("Error fetching channels:", error);
        setToast({
          show: true,
          message: "Error fetching channels",
          type: "danger",
        });
      });
  }, []);

  const responseFacebook = (response) => {
    if (response.accessToken) {
      const userAccessToken = response.accessToken;

      fetch(
        `https://graph.facebook.com/me/accounts?access_token=${userAccessToken}`
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.data && data.data.length > 0) {
            const pageAccessToken = data.data[0].access_token;
            const pageId = data.data[0].id;

            axios
              .post(`${config.apiHost}:${config.apiPort}/chatuserchannels/add`, {
                channel_id: channel_id,
                access_token: userAccessToken,
                page_token: pageAccessToken,
                pageId: pageId,
              })
              .then(() => {
                setToast({
                  show: true,
                  message: "Channel updated successfully!",
                  type: "success",
                });
              })
              .catch((error) => {
                console.error("Error posting to page:", error);
                setToast({
                  show: true,
                  message: "Failed to update channel.",
                  type: "danger",
                });
              });
          } else {
            setToast({
              show: true,
              message: "No pages found for this account.",
              type: "warning",
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching pages:", error);
          setToast({
            show: true,
            message: "Error fetching Facebook pages.",
            type: "danger",
          });
        });
    } else {
      setToast({
        show: true,
        message: "Facebook login failed.",
        type: "danger",
      });
    }
  };

  return (
    <div className="container-fluid pt-5">
      <div className="row">
        <div className="col-8 main-container p-4">
          <h1>Facebook OAuth Example</h1>
          <FacebookLogin
            appId="1666617154099075" // Replace with your Facebook App ID
            fields="name,email,picture"
            scope="public_profile,email,pages_show_list,pages_manage_posts,pages_read_engagement"
            callback={responseFacebook}
            icon="fa-facebook"
          />
        </div>
        <div className="col-4 extra-container"></div>
      </div>
      {/* Toast Notification */}
      {toast.show && (
        <div
          className="toast-container position-fixed start-50 translate-middle p-3"
          style={{ zIndex: 5, top: 550 }}
        >
          <div className={`toast show bg-${toast.type} text-white`} role="alert">
            <div className="toast-body">{toast.message}</div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Facebook;
