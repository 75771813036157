import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../../config'; // Assumes your API host and port are defined here
import { useNavigate } from 'react-router-dom'; // For navigation

const Campaigns = () => {
  const [user, setUser] = useState(null);
  const [formData, setFormData] = useState({
    campaignName: '',
    route: '',
    fromName: '',
    fromEmail: '',
    subject: '',
    emailAudience: [],
  });
  const [emailInput, setEmailInput] = useState('');
  const [templates, setTemplates] = useState([]); // Store email templates
  const [selectedTemplate, setSelectedTemplate] = useState(''); // Store selected template
  const navigate = useNavigate();

  useEffect(() => {
    const storedUser = sessionStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    } else {
      alert('You must be logged in to access this page.');
      navigate('/login'); // Redirect to login if no user is stored
    }
  }, [navigate]);

  useEffect(() => {
    // Fetch email templates from the backend
    const fetchTemplates = async () => {
      try {
        const response = await axios.get(`${config.apiHost}:${config.apiPort}/templates`);
        setTemplates(response.data); // Assuming templates are returned in `response.data.templates`
      } catch (error) {
        console.error('Error fetching templates:', error);
        alert('Failed to load email templates.');
      }
    };

    fetchTemplates();
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleEmailInputChange = (e) => {
    setEmailInput(e.target.value);
  };

  const handleEmailKeyDown = (e) => {
    if (e.key === 'Enter' && emailInput.trim()) {
      e.preventDefault();
      if (validateEmail(emailInput)) {
        setFormData({
          ...formData,
          emailAudience: [...formData.emailAudience, emailInput.trim()],
        });
        setEmailInput('');
      } else {
        alert('Please enter a valid email address.');
      }
    }
  };

  const removeEmail = (index) => {
    setFormData({
      ...formData,
      emailAudience: formData.emailAudience.filter((_, i) => i !== index),
    });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!user) {
      alert('User is not logged in');
      return;
    }

    const payload = {
      user_id: user.id,
      name: formData.campaignName,
      route: formData.route,
      from_name: formData.fromName,
      from_email: formData.fromEmail,
      subject: formData.subject,
      email_audience: formData.emailAudience,
      template_id: selectedTemplate, // Pass the selected template ID
      start_date: new Date().toISOString().slice(0, 10),
    };
    
    try {
      const response = await axios.post(
        `${config.apiHost}:${config.apiPort}/campaigns`,
        payload
      );
      alert('Campaign created successfully!');
      console.log('Response:', response.data);
    } catch (error) {
      console.error('Error creating campaign:', error.response?.data || error.message);
      alert('Failed to create campaign. Please try again.');
    }
  };

  return (
    <div className='container-fluid pt-4'>
      <div className='row'>
        <div className='col-md-8 col-sm-12 p-4 main-container bg-white shadow rounded'>
          <div>
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h2 className="h4">Campaign Builder - Email</h2>
              <div>
                <button
                  type="button"
                  className="btn btn-outline-secondary me-2"
                  onClick={() => setFormData({ ...formData, emailAudience: [] })}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSubmit}
                >
                  Send Campaign
                </button>
              </div>
            </div>

            <form onSubmit={handleSubmit}>
              {/* Campaign Name and Route */}
              <div className="row mb-3">
                <div className="col-md-6 p-2">
                  <label htmlFor="campaignName" className="form-label">
                    Campaign Name
                  </label>
                  <input
                    type="text"
                    id="campaignName"
                    name="campaignName"
                    value={formData.campaignName}
                    onChange={handleInputChange}
                    className="form-control"
                    placeholder="Enter"
                    required
                  />
                </div>
                <div className="col-md-6 p-2">
                  <label htmlFor="route" className="form-label">
                    Route
                  </label>
                  <select
                    id="route"
                    name="route"
                    value={formData.route}
                    onChange={handleInputChange}
                    className="form-select"
                    required
                  >
                    <option value="">Select Route</option>
                    <option value="Route1">Route 1</option>
                    <option value="Route2">Route 2</option>
                  </select>
                </div>
              </div>

              {/* From Name and Email */}
              <div className="row mb-3">
                <div className="col-md-6 p-2">
                  <label htmlFor="fromName" className="form-label">
                    From Name
                  </label>
                  <input
                    type="text"
                    id="fromName"
                    name="fromName"
                    value={formData.fromName}
                    onChange={handleInputChange}
                    className="form-control"
                    placeholder="Enter"
                    required
                  />
                </div>
                <div className="col-md-6 p-2">
                  <label htmlFor="fromEmail" className="form-label">
                    From Email
                  </label>
                  <input
                    type="email"
                    id="fromEmail"
                    name="fromEmail"
                    value={formData.fromEmail}
                    onChange={handleInputChange}
                    className="form-control"
                    placeholder="mailer@m1.housingscape.in"
                    required
                  />
                </div>
              </div>

              {/* Subject */}
              <div className="mb-3">
                <label htmlFor="subject" className="form-label">
                  Subject
                </label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Enter"
                  required
                />
              </div>

              {/* Email Audience */}
              <div className="mb-3">
                <label htmlFor="emailAudience" className="form-label h5">
                  Email Audience
                </label>
                <div className="campaign-tags-input form-control">
                  {formData.emailAudience.map((email, index) => (
                    <span className="campaign-tag" key={index}>
                      {email}
                      <button
                        type="button"
                        className="btn-close btn-close-white"
                        aria-label="Close"
                        onClick={() => removeEmail(index)}
                      ></button>
                    </span>
                  ))}
                  <input
                    type="text"
                    value={emailInput}
                    onChange={handleEmailInputChange}
                    onKeyDown={handleEmailKeyDown}
                    className="campaign-tags-input-field"
                    placeholder="Enter email and press Enter"
                  />
                </div>
              </div>

              {/* Email Template Dropdown */}
              <div className="mb-3">
                <label htmlFor="emailTemplate" className="form-label h5">
                  Email Template
                </label>
                <select
                  id="emailTemplate"
                  name="emailTemplate"
                  value={selectedTemplate}
                  onChange={(e) => setSelectedTemplate(e.target.value)}
                  className="form-select"
                >
                  <option value="">Select Template</option>
                  {templates.map((template) => (
                    <option key={template.id} value={template.id}>
                      {template.template_name}
                    </option>
                  ))}
                </select>
              </div>
            </form>
          </div>
        </div>
        <div className='col-md-4 col-sm-12 extra-container'></div>
      </div>
    </div>
  );
};

export default Campaigns;
