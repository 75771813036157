import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Validation from "../../validations/loginValidation";
import axios from 'axios';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import config from '../../config';
import AuthContext from '../../contexts/AuthContext';

function Login() {
    const [values, setValues] = useState({ emailOrUsername: "", password: "" });
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [toast, setToast] = useState({ show: false, message: "", type: "" });
    const { login } = useContext(AuthContext);

    const handleInput = (event) => {
        const { name, value } = event.target;
        setValues((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const err = Validation(values);
        setErrors(err);

        if (err.emailOrUsername === "" && err.password === "") {
            setLoading(true);
            axios.post(`${config.apiHost}:${config.apiPort}/login`, values)
                .then((res) => {
                    setLoading(false);
                    if (res.data.status === "Success") {
                        const userData = res.data.user;
                        const authToken = res.data.authToken;
                        setToast({ show: true, message: "Logged in successfully!", type: "success" });
                        
                        setTimeout(() => {
                            setToast({ show: false });
                            login(userData);
							sessionStorage.setItem('authToken', authToken);
                            navigate('/home');
                        }, 2000);

                    } else {
                        setToast({ show: true, message: res.data.message, type: "danger" });
                        setTimeout(() => setToast({ show: false }), 3000);
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    console.log('Error sending data:', err);
                    setToast({ show: true, message: "Login failed. Please try again.", type: "danger" });
                    setTimeout(() => setToast({ show: false }), 3000);
                });
        }
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="d-flex justify-content-center align-items-center bg-info vh-100">
            <div className='bg-white p-4 rounded-3 shadow-sm' style={{ maxWidth: '500px', width: "90%" }}>
                <h2 className="text-center mb-4">Login</h2>
                <form action="" onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="emailOrUsername" className="form-label">
                            <b>Username or Email</b>
                        </label>
                        <input
                            type="text"
                            placeholder="Enter Username or Email"
                            onChange={handleInput}
                            name="emailOrUsername"
                            className="form-control rounded-3"
                            disabled={loading}
                        />
                        {errors.emailOrUsername && (
                            <span className="text-danger">{errors.emailOrUsername}</span>
                        )}
                    </div>
                    <div className="mb-3">
                        <label htmlFor="password" className="form-label">
                            <b>Password</b>
                        </label>
                        <div className="input-group">
                            <input
                                type={showPassword ? "text" : "password"}
                                placeholder="Enter Password"
                                onChange={handleInput}
                                name="password"
                                className="form-control rounded-3"
                                disabled={loading}
                            />
                            <span className="input-group-text" onClick={toggleShowPassword}>
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </span>
                        </div>
                        {errors.password && (
                            <span className="text-danger">{errors.password}</span>
                        )}
                    </div>
                    <button
                        type="submit"
                        className="btn btn-success w-100 rounded-3 mb-3"
                        disabled={loading}
                    >
                        {loading ? "Please wait..." : "Log in"}
                    </button>
                    <div className="d-flex flex-row justify-content-between">
                        <Link to="/signup" className="text-decoration-none">
                            Create Account
                        </Link>
                        <Link to="/forgot-password" className="text-decoration-none">
                            Forgot Password?
                        </Link>
                    </div>
                </form>
            </div>
            {toast.show && (
                <div className={`toast-container position-fixed start-50 translate-middle p-3`} style={{ zIndex: 5 , top: 550 }}>
                    <div className={`toast show bg-${toast.type} text-white`} role="alert">
                        <div className="toast-body">{toast.message}</div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Login;
