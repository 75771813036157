import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../config';
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [role, setRole] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
	//fetchUserDetails();  
    const storedUser = sessionStorage.getItem('user');
    if (storedUser) {
	  setUser(JSON.parse(storedUser));
	  setIsAuthenticated(true);
	  
    }
    setLoading(false);
  }, []);

  const fetchUserDetails = async () => {
    const token = sessionStorage.getItem('authToken');
	console.log("fetchUserDetails",token);
    if (!token) return;

    const response = await fetch(`${config.apiHost}:${config.apiPort}/user/fetchdetails`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });

    if (response.ok) {
      const userDetails = await response.json();
      setUser(userDetails);
    }
  };	

  const login = (userData) => {
	  
    sessionStorage.setItem('user', JSON.stringify(userData));
	setUser(userData);
	setRole(userData.role);
    setIsAuthenticated(true);
	
	
	
	
    navigate('/home');
  };
  
  

  const logout = () => {
    sessionStorage.removeItem('user');
    setUser(null);
	setRole("");
    setIsAuthenticated(false);
    navigate('/');
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, role, login, logout,loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
