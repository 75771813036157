import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import config from '../../config';

const AddProfile = () => {
  const navigate = useNavigate();
  const [profile, setProfile] = useState({
    name: "",
    mobile: "", // Ensure the backend expects this field
    email: "",
  });
  const [toast, setToast] = useState({ show: false, message: '', type: '' });

  const handleChange = (e) => {
    setProfile({ ...profile, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Ensure backend expects mobile instead of phone
      await axios.post(`${config.apiHost}:${config.apiPort}/profiles`, profile);
      
      // Show success toast
      setToast({
        show: true,
        message: 'Profile added successfully!',
        type: 'success',
      });
      
      // Hide toast after 3 seconds and redirect
      setTimeout(() => {
        setToast({ show: false });
        navigate('/profile');
      }, 3000);
      
    } catch (error) {
      console.error("Error adding profile:", error);
      
      // Show error toast
      setToast({
        show: true,
        message: 'Failed to add profile!',
        type: 'danger',
      });
      
      // Hide error toast after 3 seconds
      setTimeout(() => setToast({ show: false }), 3000);
    }
  };

  return (
    <div className="container mt-5">
      <h2>Add Profile</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label className="form-label">Name</label>
          <input
            type="text"
            className="form-control"
            name="name"
            value={profile.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Mobile</label>
          <input
            type="text"
            className="form-control"
            name="mobile"
            value={profile.mobile}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Email</label>
          <input
            type="email"
            className="form-control"
            name="email"
            value={profile.email}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" className="btn btn-outline-dark">Add Profile</button>
      </form>

      {/* Toast Notification */}
      {toast.show && (
        <div className={`toast-container position-fixed start-50 translate-middle p-3`} style={{ zIndex: 5, top: 550 }}>
          <div className={`toast show bg-${toast.type} text-white`} role="alert">
            <div className="toast-body">{toast.message}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddProfile;
