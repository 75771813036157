import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import config from '../../config';

const ChangePassword = () => {
  const [values, setValues] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  });
  const [errors, setErrors] = useState({});
  const [serverError, setServerError] = useState('');
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const storedUser = sessionStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    } else {
      navigate('/login');
    }
  }, [navigate]);

  const handleInput = (event) => {
    const { name, value } = event.target;
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validate = () => {
    let err = {};
    if (!values.currentPassword) {
      err.currentPassword = 'Current password is required';
    }
    if (!values.newPassword) {
      err.newPassword = 'New password is required';
    }
    if (values.newPassword !== values.confirmNewPassword) {
      err.confirmNewPassword = 'Passwords do not match';
    }
    return err;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const err = validate(values);
    setErrors(err);

    if (Object.keys(err).length === 0) {
      setLoading(true);
      try {
        const response = await axios.put(`${config.apiHost}:${config.apiPort}/change-password`, {
          email: user.email,
          currentPassword: values.currentPassword,
          newPassword: values.newPassword,
          confirmNewPassword: values.confirmNewPassword
        });
        console.log(response);
        alert('Password changed successfully');
        setLoading(false);
        setValues({ currentPassword: '', newPassword: '', confirmNewPassword: '' });
        navigate('/');
      } catch (error) {
        setLoading(false);
        setServerError(error.response?.data?.error || 'Error changing password');
      }
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center bg-light pt-5">
      <div className='bg-white p-4 rounded-3 shadow-sm border' style={{ maxWidth: '500px', width: "90%" }}>
        <h2 className="text-center mb-4">Change Password</h2>
        {serverError && <div className="alert alert-danger">{serverError}</div>}
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="currentPassword" className="form-label"><b>Current Password</b></label>
            <input
              type="password"
              name="currentPassword"
              placeholder="Enter Current Password"
              className="form-control rounded-3"
              onChange={handleInput}
            />
            {errors.currentPassword && (<span className="text-danger">{errors.currentPassword}</span>)}
          </div>
          <div className="mb-3">
            <label htmlFor="newPassword" className="form-label"><b>New Password</b></label>
            <input
              type="password"
              name="newPassword"
              placeholder="Enter New Password"
              className="form-control rounded-3"
              onChange={handleInput}
            />
            {errors.newPassword && (<span className="text-danger">{errors.newPassword}</span>)}
          </div>
          <div className="mb-3">
            <label htmlFor="confirmNewPassword" className="form-label"><b>Confirm New Password</b></label>
            <input
              type="password"
              name="confirmNewPassword"
              placeholder="Confirm New Password"
              className="form-control rounded-3"
              onChange={handleInput}
            />
            {errors.confirmNewPassword && (<span className="text-danger">{errors.confirmNewPassword}</span>)}
          </div>
          <div className='d-flex justify-content-center'>
            <button type="submit" className="btn btn-outline-dark rounded-3" disabled={loading}>
              {loading ? 'Changing...' : 'Change Password'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
