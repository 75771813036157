function Validation(values) {
    let error = {};
    const email_pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    if (values.emailOrUsername === "") {
        error.emailOrUsername = "Username or Email should not be empty";
    } else if (!email_pattern.test(values.emailOrUsername) && values.emailOrUsername.includes('@')) {
        error.emailOrUsername = "Email format is incorrect";
    } else {
        error.emailOrUsername = "";
    }

    if (values.password === "") {
        error.password = "Password should not be empty";
    } else {
        error.password = "";
    }

    return error;
}

export default Validation;
