import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Home = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Check if 'user' data exists in sessionStorage
    const storedUser = sessionStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  return (
    <div className="container-fluid pt-5">
      <div className="row">
        <div className="col-8 main-container">
          <div className="p-3">
            <div className="container">
              <h1>Dashboard</h1>

              {user && (
                <div className="mb-4">
                  <h3 style={{ color: "#fc7b12" }}>
                    Welcome, {user.username}!
                  </h3>
                </div>
              )}

              <div className="row mt-4">
                <div className="col-md-5">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Lead Details</h5>
                      <p className="card-text">
                        View and update your lead details here.
                      </p>
                      <Link to="/leads-list" className="btn btn-outline-dark">
                        Go to Leads Details
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 m-auto">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Users List</h5>
                      <p className="card-text">
                        View and update your user details.
                      </p>
                      <Link to="/users-list" className="btn btn-outline-dark">
                        Go to Users List
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 mt-5">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Lead Form</h5>
                      <p className="card-text">Fill up the Leads.</p>
                      <Link to="/add-lead" className="btn btn-outline-dark">
                        Go to Lead Form
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-4 extra-container"></div>
      </div>
    </div>
  );
};

export default Home;
