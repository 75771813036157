import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import config from '../../config';

const EditUser = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState({ username: '', first_name: '', last_name: '', email: '', mobile: '' });
  const [toast, setToast] = useState({ show: false, message: "", type: "" });

  useEffect(() => {
    axios.get(`${config.apiHost}:${config.apiPort}/user/${id}`)
      .then(response => {
        const { username, first_name, last_name, mobile, email } = response.data; // Destructure mobile
        setUser({ username, first_name, last_name, mobile, email }); // Set individual fields
        console.log(response.data);
      })
      .catch(error => {
        console.error('Error fetching user:', error);
      });
  }, [id]);

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.put(`${config.apiHost}:${config.apiPort}/update-user/${id}`, user)
      .then(response => {
        setToast({
          show: true,
          message: "User updated successfully!",
          type: "success",
        });
        setTimeout(() => {
          setToast({ show: false });
          navigate('/users');
        }, 3000);
      })
      .catch(error => {
        console.error('Error updating user:', error);
        setToast({
          show: true,
          message: "Failed to update user",
          type: "danger",
        });
        setTimeout(() => setToast({ show: false }), 3000);
      });
  };

  return (
    <div className="container mt-5">
      <h2>Edit User</h2>
      <form onSubmit={handleSubmit}>
      <div className="mb-3">
          <label htmlFor="username" className="form-label">User Name</label>
          <input
          readOnly
            type="text"
            className="form-control"
            id="username"
            name="username"
            value={user.username}
            onChange={handleChange}
            
          />
        </div>
        <div className="mb-3">
          <label htmlFor="first_name" className="form-label">First Name</label>
          <input
            type="text"
            className="form-control"
            id="first_name"
            name="first_name"
            value={user.first_name}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="last_name" className="form-label">Last Name</label>
          <input
            type="text"
            className="form-control"
            id="last_name"
            name="last_name"
            value={user.last_name}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="mobile" className="form-label">Mobile</label>
          <input
            type="text"
            className="form-control"
            id="mobile"
            name="mobile"
            value={user.mobile}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">Email</label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            value={user.email}
            onChange={handleChange}
          />
        </div>
        
        <button type="submit" className="btn btn-primary">Save Changes</button>
      </form>
      {toast.show && (
        <div
          className={`toast-container position-fixed start-50 translate-middle p-3`}
          style={{ zIndex: 5, top: 550 }}
        >
          <div
            className={`toast show bg-${toast.type} text-white`}
            role="alert"
          >
            <div className="toast-body">{toast.message}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditUser;
