import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../config";

const EditLead = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [values, setValues] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [toast, setToast] = useState({ show: false, message: "", type: "" });

  useEffect(() => {
    axios
      .get(`${config.apiHost}:${config.apiPort}/lead/${id}`)
      .then((response) => {
        setValues(response.data);
      })
      .catch((error) => {
        console.error("Error fetching lead:", error);
      });
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .put(`${config.apiHost}:${config.apiPort}/update-lead/${id}`, values)
      .then(() => {
        setToast({
          show: true,
          message: "Lead updated successfully!",
          type: "success",
        });
        setTimeout(() => {
          setToast({ show: false });
          navigate("/leads-list");
        }, 3000);
      })
      .catch((error) => {
        setToast({
          show: true,
          message: "Failed to update lead",
          type: "danger",
        });
        setTimeout(() => setToast({ show: false }), 3000);
      });
  };

  return (
    <div className="container-fluid pt-5">
      <div className="row">
        <div className="col-8 main-container">
          <div className="container">
          <h2>Edit Lead</h2>
          <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="name" className="form-label">
              Name
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              value={values.name}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              value={values.email}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="phone" className="form-label">
              Phone
            </label>
            <input
              type="text"
              className="form-control"
              id="phone"
              name="phone"
              value={values.phone}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="message" className="form-label">
              Message
            </label>
            <textarea
              className="form-control lead-textarea"
              id="message"
              name="message"
              value={values.message}
              onChange={handleInputChange}
            />
          </div>
          <button type="submit" className="btn btn-outline-dark">
            Update Lead
          </button>
          </form>
          {toast.show && (
          <div
            className={`toast-container position-fixed start-50 translate-middle p-3`}
            style={{ zIndex: 5, top: 550 }}
          >
            <div
              className={`toast show bg-${toast.type} text-white`}
              role="alert"
            >
              <div className="toast-body">{toast.message}</div>
            </div>
          </div>
          )}
          </div>
        </div>
        <div className="col-4 extra-container">

        </div>
      </div>
    </div>
  );
};

export default EditLead;
//updated
