import React, { useEffect, useState } from "react";
import axios from "axios";
import { BsPencilFill, BsTrash } from "react-icons/bs";
import { useNavigate, Link } from "react-router-dom";
import config from "../../config";
import Pagination from "../pagination";

const FormIndex = () => {
  const [forms, setForms] = useState([]);
  const [users, setUsers] = useState([]); // To store the list of users
  const [currentPage, setCurrentPage] = useState(1);
  const [formsPerPage, setFormsPerPage] = useState(5); // Initial default, can be updated from Pagination
  const [totalForms, setTotalForms] = useState(0);
  const navigate = useNavigate();

  // Fetch forms and users from the backend
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch forms
        const formsResponse = await axios.get(
          `${config.apiHost}:${config.apiPort}/custom-forms/forms`
        );
        setForms(formsResponse.data);
        setTotalForms(formsResponse.data.length);

        // Fetch users
        const usersResponse = await axios.get(
          `${config.apiHost}:${config.apiPort}/users-list` // Adjust the endpoint as needed
        );
        setUsers(usersResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } 
    };

    fetchData();
  }, []);

  // Get current forms for the current page
  const indexOfLastForm = currentPage * formsPerPage;
  const indexOfFirstForm = indexOfLastForm - formsPerPage;
  const currentForms = forms.slice(indexOfFirstForm, indexOfLastForm);

  // Get username by user ID
  const getUsernameById = (userId) => {
    const user = users.find((user) => user.id === userId);
    return user ? user.username : "Unknown User"; // Default to "Unknown User" if not found
  };

  // Handle edit functionality
  const handleEdit = (id) => {
    navigate(`/edit-form/${id}`);
  };

  // Handle delete functionality
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this form?")) {
      try {
        await axios.delete(
          `${config.apiHost}:${config.apiPort}/custom-forms/delete-form/${id}`
        );
        setForms(forms.filter((form) => form.id !== id)); // Remove deleted form from the state
      } catch (error) {
        console.error("Error deleting form:", error);
      }
    }
  };

  // Handle status toggle functionality
  const toggleFormStatus = async (id) => {
    const form = forms.find((f) => f.id === id);
    const updatedStatus = form.status === "enabled" ? "disabled" : "enabled";

    try {
      const updatedFormDatas = JSON.stringify({
        ...JSON.parse(form.form_datas),
        status: updatedStatus,
      });

      // Update status in the backend
      await axios.put(
        `${config.apiHost}:${config.apiPort}/custom-forms/update-form/${id}`,
        {
          form_name: form.form_name,
          form_datas: updatedFormDatas,
        }
      );

      // Update status in the state
      setForms(
        forms.map((f) =>
          f.id === id
            ? { ...f, form_datas: updatedFormDatas, status: updatedStatus }
            : f
        )
      );
    } catch (error) {
      console.error("Error updating form status:", error);
    }
  };

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="container-fluid pt-5">
      <div className="row">
        <div className="col-8 main-container">
          <div className="container">
            <div className="d-flex justify-content-between">
              <h2>Forms List</h2>
              <Link to="/add-form" className="btn btn-outline-dark mb-3">
                Add Form
              </Link>
            </div>
            <div className="table-responsive">
              <table className="table table-striped border">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Form Name</th>
                    <th>Username</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentForms.length > 0 ? (
                    currentForms.map((form, index) => {
                      const formData = JSON.parse(form.form_datas);
                      const status = formData.status || "enabled"; // Default to "enabled" if status is missing

                      // Calculate serial number
                      const serialNumber =
                        (currentPage - 1) * formsPerPage + index + 1;

                      return (
                        <tr key={form.id}>
                          <td>{serialNumber}</td>
                          <td>{form.form_name}</td>
                          <td>{getUsernameById(form.user_id)}</td>
                          <td>
                            <span
                              className={`badge ${
                                status === "enabled"
                                  ? "bg-success"
                                  : "bg-danger"
                              }`}
                              style={{ cursor: "pointer" }}
                              onClick={() => toggleFormStatus(form.id)}
                            >
                              {status.charAt(0).toUpperCase() + status.slice(1)}
                            </span>
                          </td>
                          <td>
                            <BsPencilFill
                              onClick={() => handleEdit(form.id)}
                              style={{
                                cursor: "pointer",
                                fontSize: "20px",
                                marginRight: "15px",
                              }}
                            />
                            <BsTrash
                              onClick={() => handleDelete(form.id)}
                              style={{
                                cursor: "pointer",
                                color: "red",
                                fontSize: "20px",
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="5" className="text-center">
                        No forms found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            {/* Pagination Component */}
            <Pagination
              totalItems={totalForms}
              itemsPerPage={formsPerPage}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              setItemsPerPage={setFormsPerPage} // Pass the setter to update formsPerPage
            />
          </div>
        </div>
        <div className="col-4 extra-container"></div>
      </div>
    </div>
  );
};

export default FormIndex;
