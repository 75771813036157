import React from "react";
import { Form } from "react-bootstrap";

const InputField = ({ label, placeholder, type, value, onChange, ...props }) => {
  return (
    <Form.Group controlId={props.id || label.toLowerCase().replace(" ", "_")} className="mb-3 w-75">
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control
        type={type || "text"}
        placeholder={placeholder || ""}
        value={value}
        onChange={onChange}
        {...props}
      />
    </Form.Group>
  );
};

export default InputField;
