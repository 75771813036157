import React, { useState, useEffect, useCallback, useRef } from "react";
import EmailEditor from "raven-react-email-editor";
import axios from "axios";
import config from "../../config";
import { useNavigate } from "react-router-dom";

const Emails = () => {
  const [templateName, setTemplateName] = useState(""); // Template name state
  const [savedState, setSavedState] = useState({ state: "", html: "" });
  const [isLoaded, setIsLoaded] = useState(false);
  const [user, setUser] = useState(null); // To store logged-in user
  const [toast, setToast] = useState({ show: false, message: "", type: "" }); // Toast state
  const editorRef = useRef(null);
  const navigate = useNavigate();

  // Check for logged-in user on mount
  useEffect(() => {
    const storedUser = sessionStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    } else {
      setToast({
        show: true,
        message: "You must be logged in to access this page.",
        type: "error",
      });
      navigate("/login"); // Redirect to login if no user is stored
    }
  }, [navigate]);

  // Callback when the editor is loaded
  const onLoad = () => {
    setIsLoaded(true);
  };

  // Save the template
  const onEditorSave = useCallback(() => {
    if (isLoaded) {
      if (!templateName.trim()) {
        setToast({
          show: true,
          message: "Please provide a name for the template.",
          type: "warning",
        });
        return;
      }
      if (!user) {
        setToast({
          show: true,
          message: "User not logged in!",
          type: "error",
        });
        return;
      }

      setIsLoaded(false);
      editorRef.current.fetchState(); // Fetch state triggers the `onFetched` callback
    }
  }, [isLoaded, templateName, user]);

  // Handle fetched state and HTML
  const onFetched = useCallback(
    async (state, html) => {
      setSavedState({ state, html });
      console.log("Fetched State and HTML", { state, html });

      if (html.trim()) {
        const templateData = {
          template_name: templateName,
          template_type: "email",
          user_id: user.id,
          template_content: html,
          editor_state: JSON.stringify(state),
        };

        try {
          const response = await axios.post(
            `${config.apiHost}:${config.apiPort}/templates`,
            templateData
          );
          setToast({
            show: true,
            message: "Template saved successfully!",
            type: "success",
          });
          console.log("Response:", response.data);
          navigate("/email-editor");
        } catch (error) {
          console.error("Error saving template:", error);
          setToast({
            show: true,
            message: "Failed to save the template. Please try again.",
            type: "error",
          });
        } finally {
          setIsLoaded(true);
        }
      } else {
        setToast({
          show: true,
          message: "No content to save!",
          type: "warning",
        });
        setIsLoaded(true);
      }
    },
    [templateName, user, navigate]
  );

  // Toast timeout effect
  useEffect(() => {
    if (toast.show) {
      const timer = setTimeout(() => {
        setToast((prevToast) => ({ ...prevToast, show: false }));
      }, 12000); // Hide toast after 3 seconds
      return () => clearTimeout(timer); // Clean up timer on unmount
    }
  }, [toast]);

  return (
    <div className="p-4">
      {/* Toast Notification */}
      {toast.show && (
        <div
          className={`toast-container position-fixed start-50 translate-middle p-3`}
          style={{ zIndex: 9999, top: "80vh", transform: "translateX(-50%)" }}
        >
          <div
            className={`toast show bg-${toast.type} text-white`}
            role="alert"
          >
            <div className="toast-body">{toast.message}</div>
          </div>
        </div>
      )}

      {/* Header Section */}
      <div className="d-flex justify-content-between mb-3 align-items-center">
        <h2>Email Editor</h2>
        <div className="d-flex align-items-center">
          {/* Template Name Input */}
          <input
            type="text"
            className="form-control me-2"
            placeholder="Template Name"
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
            style={{ maxWidth: "300px" }}
          />
          <button
            className="btn btn-primary"
            onClick={onEditorSave}
            disabled={!isLoaded} // Disable button until the editor loads
          >
            Save
          </button>
        </div>
      </div>

      {/* Email Editor Section */}
      <div
        className="border rounded shadow-sm bg-white"
        style={{ height: "70vh", overflow: "hidden" }}
      >
        <EmailEditor
          state={savedState.state} // Load saved state if available
          onEditorLoad={onLoad} // Triggered when the editor loads
          onFetched={onFetched} // Triggered when state is fetched
          ref={editorRef}
        />
      </div>
    </div>
  );
};

export default Emails;
