import React from "react";

const SwitchCheckbox = ({ value, label, onChange }) => {
  return (
    <div className="form-check form-switch">
      <input
        className="form-check-input"
        type="checkbox"
        value={value}
        id={`switch-${value}`}
        onChange={onChange}
      />
      <label className="form-check-label" htmlFor={`switch-${value}`}>
        {label}
      </label>
    </div>
  );
};

export default SwitchCheckbox;
