import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import config from '../../config';

const AddUser = () => {
  const [roles, setRoles] = useState([]);
  const [newUser, setNewUser] = useState({
    username: '',
    firstName: '',
    lastName: '',
    mobile: '',
    email: '',
    password: '',
    role: '' // Role selected from dropdown
  });
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch roles for dropdown
    axios.get(`${config.apiHost}:${config.apiPort}/roles`)
      .then(response => {
        setRoles(response.data);
      })
      .catch(error => {
        console.error('Error fetching roles:', error);
      });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser(prev => ({ ...prev, [name]: value }));
  };

  const handleAddUser = (e) => {
    e.preventDefault();
    axios.post(`${config.apiHost}:${config.apiPort}/signup`, newUser)
      .then(response => {
        navigate('/users'); // Redirect back to Users List after adding the user
      })
      .catch(error => {
        console.error('Error adding user:', error);
      });
  };

  return (
    <div className="container mt-5">
      <h2>Add New User</h2>
      <form onSubmit={handleAddUser}>
        <div className="mb-3">
          <label className="form-label">Username</label>
          <input
            type="text"
            name="username"
            className="form-control"
            value={newUser.username}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label">First Name</label>
          <input
            type="text"
            name="firstName"
            className="form-control"
            value={newUser.firstName}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Last Name</label>
          <input
            type="text"
            name="lastName"
            className="form-control"
            value={newUser.lastName}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Mobile</label>
          <input
            type="text"
            name="mobile"
            className="form-control"
            value={newUser.mobile}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Email</label>
          <input
            type="email"
            name="email"
            className="form-control"
            value={newUser.email}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Password</label>
          <input
            type="password"
            name="password"
            className="form-control"
            value={newUser.password}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Role</label>
          <select
            name="role"
            value={newUser.role}
            className="form-control"
            onChange={handleInputChange}
            required
          >
            <option value="">Select Role</option>
            {roles.map(role => (
              <option key={role.id} value={role.id}>
                {role.display_name}
              </option>
            ))}
          </select>
        </div>
        <button type="submit" className="btn btn-primary">Add User</button>
      </form>
    </div>
  );
};

export default AddUser;
