import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { SurveyCreator, SurveyCreatorComponent } from "survey-creator-react";
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";
import config from "../../config";

const EditForm = () => {
  const { id } = useParams(); // Get form ID from URL
  const [creator, setCreator] = useState(null);
  const [formName, setFormName] = useState("");
  const [status, setStatus] = useState("enabled"); // Track form status
  const [toast, setToast] = useState({ show: false, message: "", type: "" }); // Toast state
  const navigate = useNavigate();

  useEffect(() => {
    const surveyCreator = new SurveyCreator({
      showLogicTab: true,
      isAutoSave: false,
    });
    setCreator(surveyCreator);

    // Fetch the form data by ID
    const fetchFormData = async () => {
      try {
        const response = await axios.get(
          `${config.apiHost}:${config.apiPort}/custom-forms/form/${id}`
        );
        setFormName(response.data.form_name);
        const formData = JSON.parse(response.data.form_datas);
        setStatus(formData.status || "enabled"); // Set initial status
        surveyCreator.JSON = formData;
      } catch (error) {
        console.error("Error fetching form data:", error);
        setToast({
          show: true,
          message: "Error fetching form data.",
          type: "danger",
        });
      }
    };

    fetchFormData();

    return () => {
      if (surveyCreator) surveyCreator.dispose();
    };
  }, [id]);

  const handleUpdateForm = async () => {
    if (!creator) {
      setToast({
        show: true,
        message: "Survey Creator is not initialized.",
        type: "warning",
      });
      return;
    }

    if (!formName || formName.trim() === "") {
      setToast({
        show: true,
        message: "Form name cannot be empty!",
        type: "warning",
      });
      return;
    }

    const form_datas = JSON.stringify({
      ...creator.JSON,
      status: status, // Include the status in JSON
    });

    try {
      const response = await axios.put(
        `${config.apiHost}:${config.apiPort}/custom-forms/update-form/${id}`,
        { form_name: formName, form_datas }
      );

      if (response.status === 200) {
        setToast({
          show: true,
          message: "Form updated successfully!",
          type: "success",
        });
        setTimeout(() => {
          setToast({ show: false });
          navigate(`/forms-list/`);
        }, 3000);
      } else {
        setToast({
          show: true,
          message: "Failed to update the form. Please try again.",
          type: "danger",
        });
      }
    } catch (error) {
      console.error("Error updating form:", error);
      setToast({
        show: true,
        message: "An error occurred while updating the form.",
        type: "danger",
      });
    }
  };

  return (
    <div className="p-4">
      <div className="d-flex justify-content-between p-2">
        <div>
          <h2>Edit Form</h2>
        </div>
        <div>
          <button
            onClick={handleUpdateForm}
            className="btn btn-outline-success fw-bold"
          >
            Update Form
          </button>
        </div>
      </div>
      <div className="mb-3">
        <label htmlFor="formName" className="form-label">
          Form Name:
        </label>
        <input
          type="text"
          id="formName"
          className="form-control"
          value={formName}
          onChange={(e) => setFormName(e.target.value)}
        />
      </div>
      {/* Switch for enabling/disabling the form */}
      <div className="form-check form-switch mb-3">
        <input
          className="form-check-input"
          style={{ cursor: "pointer" }}
          type="checkbox"
          id="formStatusSwitch"
          checked={status === "enabled"}
          onChange={() => setStatus(status === "enabled" ? "disabled" : "enabled")}
        />
        <label className="form-check-label" htmlFor="formStatusSwitch">
          {status === "enabled" ? "Enabled" : "Disabled"}
        </label>
      </div>
      {creator && <SurveyCreatorComponent creator={creator} />}
      {/* Toast Notification */}
      {toast.show && (
        <div
          className={`toast-container position-fixed start-50 translate-middle p-3`}
          style={{ zIndex: 5, top: 550 }}
        >
          <div className={`toast show bg-${toast.type} text-white`} role="alert">
            <div className="toast-body">{toast.message}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditForm;
