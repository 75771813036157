import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import { BsPencilFill, BsTrash } from "react-icons/bs";
import { Link } from "react-router-dom";
import config from "../../config";
 
const FullCalendarComponent = () => {
  const [events, setEvents] = useState([]);
  const [toast, setToast] = useState({ show: false, type: "", message: "" }); // Toast state

  // Fetch posts from the API and format them for FullCalendar
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch(`${config.apiHost}:${config.apiPort}/posts/`);
        const posts = await response.json();

        // Format posts as events
        const formattedEvents = posts.map((post) => ({
          id: post.id.toString(),
          title: post.content,
          start: post.post_date,
          extendedProps: {
            channelId: post.channel_id,
            status: post.status,
            mediaUrl: post.media_url,
            userId: post.user_id,
          },
        }));

        setEvents(formattedEvents);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };

    fetchPosts();
  }, []);

  // Edit event and save to the backend
  const handleEditEvent = async (eventId) => {
    const eventToEdit = events.find((event) => event.id === eventId);
    const newTitle = prompt("Enter a new title for the event:", eventToEdit.title);
    if (newTitle && newTitle.trim() !== "") {
      const updatedEvent = { ...eventToEdit, title: newTitle.trim() };

      try {
        const response = await fetch(
          `${config.apiHost}:${config.apiPort}/posts/${eventId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ content: newTitle.trim() }),
          }
        );

        if (response.ok) {
          setEvents((prevEvents) =>
            prevEvents.map((event) => (event.id === eventId ? updatedEvent : event))
          );
          showToast("success", "Event updated successfully!");
        } else {
          showToast("danger", "Failed to update the event. Please try again.");
        }
      } catch (error) {
        console.error("Error updating event:", error);
        showToast("danger", "An error occurred while updating the event.");
      }
    } else if (newTitle === "") {
      showToast("warning", "Event title cannot be empty!");
    }
  };

  // Delete event from the backend
  const handleDeleteEvent = async (eventId) => {
    if (window.confirm("Are you sure you want to delete this event?")) {
      try {
        const response = await fetch(
          `${config.apiHost}:${config.apiPort}/posts/${eventId}`,
          {
            method: "DELETE",
          }
        );

        if (response.ok) {
          setEvents((prevEvents) => prevEvents.filter((event) => event.id !== eventId));
          showToast("success", "Event deleted successfully!");
        } else {
          showToast("danger", "Failed to delete the event. Please try again.");
        }
      } catch (error) {
        console.error("Error deleting event:", error);
        showToast("danger", "An error occurred while deleting the event.");
      }
    }
  };

  // Render event content with icons
  const renderEventContent = (eventInfo) => {
    return (
      <div className="event-content-wrapper">
        <span className="event-title">{eventInfo.event.title}</span>
        <div className="event-icons">
          <BsPencilFill
            className="edit-icon text-primary me-2"
            style={{ cursor: "pointer" }}
            onClick={() => handleEditEvent(eventInfo.event.id)}
          />
          <BsTrash
            className="delete-icon text-danger"
            style={{ cursor: "pointer" }}
            onClick={() => handleDeleteEvent(eventInfo.event.id)}
          />
        </div>
      </div>
    );
  };

  // Show toast with specific type and message
  const showToast = (type, message) => {
    setToast({ show: true, type, message });
    setTimeout(() => setToast({ show: false, type: "", message: "" }), 3000); // Hide toast after 3 seconds
  };

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-between my-3">
        <h2>Post Scheduler</h2>
        <div>
        <Link to="/add-post" className="btn btn-outline-dark">Add Post</Link>
        </div>
      </div>
      {toast.show && (
        <div
          className={`toast-container position-fixed start-50 translate-middle p-3`}
          style={{ zIndex: 5, top: 550 }}
        >
          <div className={`toast show bg-${toast.type} text-white`} role="alert">
            <div className="toast-body">{toast.message}</div>
          </div>
        </div>
      )}
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
        initialView="dayGridMonth"
        editable={true}
        selectable={true}
        events={events}
        eventContent={renderEventContent} // Custom render function
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
        }}
      />
    </div>
  );
};

export default FullCalendarComponent;
