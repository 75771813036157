import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import config from '../../config';


const LeadForm = () => {
    const [values, setValues] = useState({
        name: "",
        email: "",
        phone: "",
        message: ""
    });
    const [toast, setToast] = useState({ show: false, message: '', type: '' });
    const navigate = useNavigate();

    const handleInput = (event) => {
        const { name, value } = event.target;
        setValues((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        axios.post(`${config.apiHost}:${config.apiPort}/submit-lead`, values)
            .then((res) => {
                setToast({ show: true, message: 'Lead submitted successfully!', type: 'success' });
                setTimeout(() => {
                    setToast({ show: false });
                    navigate('/leads-list');
                }, 3000);
            })
            .catch((err) => {
                setToast({ show: true, message: 'Failed to submit lead', type: 'danger' });
                setTimeout(() => setToast({ show: false }), 3000);
            });
    };

    return (
        <div className='container-fluid pt-4'>
            <div className='row'>
                <div className='col-md-8 col-sm-12 p-4 main-container'> 
                
                    <div className='lead-sub'>
                        <h2>Add Lead</h2><hr/>
                        <form onSubmit={handleSubmit}>
                            <div className='mb-3'>
                                <label htmlFor='name'><b>Name</b></label>
                                <input
                                    type='text'
                                    placeholder='Enter Name'
                                    name='name'
                                    onChange={handleInput}
                                    className='form-control rounded-3'
                                />
                            </div>
                            <div className='mb-3'>
                                <label htmlFor='email'><b>Email</b></label>
                                <input
                                    type='email'
                                    placeholder='Enter Email'
                                    name="email"
                                    onChange={handleInput}
                                    className='form-control rounded-3'
                                />
                            </div>
                            <div className='mb-3'>
                                <label htmlFor='phone'><b>Phone</b></label>
                                <input
                                    type='text'
                                    placeholder='Enter Phone'
                                    name='phone'
                                    onChange={handleInput}
                                    className='form-control rounded-3'
                                />
                            </div>
                            <div className='mb-3'>
                                <label htmlFor='message'><b>Message</b></label>
                                <textarea
                                    placeholder='Enter Message'
                                    name='message'
                                    onChange={handleInput}
                                    className='form-control rounded-3 lead-textarea'
                                />
                            </div>
                            <div>
                                <button type="submit" className='btn btn-outline-dark rounded-3'>Submit</button>
                            </div>
                        </form>
                    </div>
                
                {toast.show && (
                    <div className={`toast-container position-fixed start-50 translate-middle p-3`} style={{ zIndex: 5 , top:550}}>
                        <div className={`toast show bg-${toast.type} text-white`} role="alert">
                            <div className="toast-body">
                                {toast.message}
                            </div>
                        </div>
                    </div>
                )}
                </div>
                
                <div className='col-md-4 col-sm-12 extra-container'>
                    
                </div>
            </div>
        </div>
    );
};

export default LeadForm;
