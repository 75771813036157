import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import config from "../../config";

const EditChannel = () => {
  const { id } = useParams();
  const [name, setName] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [toast, setToast] = useState({ show: false, message: "", type: "" });
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${config.apiHost}:${config.apiPort}/channels/${id}`)
      .then((response) => {
        setName(response.data.name);
        setDisplayName(response.data.display_name);
      })
      .catch((error) => {
        console.error("Error fetching channel:", error);
      });
  }, [id]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const trimmedName = name.trim();
    const trimmedDisplayName = displayName.trim();

    if (!trimmedName || !trimmedDisplayName) {
      setToast({
        show: true,
        message: "Fields cannot be empty or contain only spaces.",
        type: "danger",
      });
      setTimeout(() => setToast({ show: false }), 3000);
      return;
    }

    axios
      .put(`${config.apiHost}:${config.apiPort}/channels/${id}`, {
        name: trimmedName,
        display_name: trimmedDisplayName,
      })
      .then((response) => {
        console.log("Channel updated successfully:", response);
        setToast({
          show: true,
          message: "Channel updated successfully!",
          type: "success",
        });
        setTimeout(() => {
          setToast({ show: false });
          navigate("/channels-list");
        }, 3000);
      })
      .catch((error) => {
        console.error("Error updating channel:", error);
        setToast({
          show: true,
          message: "Failed to update Channel",
          type: "danger",
        });
        setTimeout(() => setToast({ show: false }), 3000);
      });
  };

  return (
    <div className="container-fluid pt-5">
      <div className="row">
        <div className="col-8 main-container">
          <div className="container">
            <h2>Edit Channel</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label className="form-label">Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Display Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={displayName}
                  onChange={(e) => setDisplayName(e.target.value)}
                  required
                />
              </div>
              <button type="submit" className="btn btn-outline-dark">
                Update
              </button>
            </form>
            {toast.show && (
              <div
                className={`toast-container position-fixed start-50 translate-middle p-3`}
                style={{ zIndex: 5, top: 550 }}
              >
                <div
                  className={`toast show bg-${toast.type} text-white`}
                  role="alert"
                >
                  <div className="toast-body">{toast.message}</div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="col-4 extra-container"></div>
      </div>
    </div>
  );
};

export default EditChannel;
//updated
