import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import AuthContext from '../contexts/AuthContext';

const ProtectedRoute = ({ children, component: Component, requiredRole, ...rest }) => {
  const { isAuthenticated ,loading, user} = useContext(AuthContext);

  if(loading){
    return <div>Loading...</div>
  }
  if (!isAuthenticated && (!requiredRole || user.name === requiredRole)) {
    return <Navigate to="/" />;
  }

  return children;
};

export default ProtectedRoute;
