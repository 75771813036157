import React, { useEffect, useState } from 'react';
import Home from './pages/home';
import LeadForm from './components/leads/add';
import Header from './components/header';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import UsersList from './components/users';
import EditUser from './components/users/edit';
import AddUser from './components/users/add';
import Login from './components/users/login';
import Signup from './components/users/signup';
import EditLead from './components/leads/edit';
import LeadList from './components/leads';
import ChannelsList from './components/channels';
import AddChannel from './components/channels/add';
import EditChannel from './components/channels/edit';
import Posts from './components/posts';
import AddPost from './components/posts/add';
import EditPost from './components/posts/edit';
import ForgotPassword from "./components/users/forgotpassword";
import ChangePassword from "./components/users/changepassword";
import ChatLog from "./components/chatlog";
import AddProfile from "./components/profiles/add";
import EditProfile from "./components/profiles/edit";
import ProfileList from "./components/profiles";
import FacebookLogin from "./components/fb/fb";
import MyProfile from './components/myprofile';
import UpdateProfile from './components/myprofile/edit';
import AddChatUserChannels from './components/chatuserchannels/add';
import ChatUserChannels from './components/chatuserchannels';
import { AuthProvider } from './contexts/AuthContext';
import ProtectedRoute from './contexts/ProtectedRoute';
import './App.css';
import AddFormBuilder from './components/formbuilder/add';
import FormBuilder from './components/formbuilder';
import EditForm from './components/formbuilder/edit';
import EmailsEditor from './components/emails';
import AddEmailsEditor from './components/emails/add';
import EditEmailsEditor from './components/emails/edit';
import Campaigns from './components/campaigns';
import config from './config';

const App = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Main />
      </AuthProvider>
    </BrowserRouter>
  );
};


const Main = () => {
  const location = useLocation();

  return (
    <>
      {/* Conditionally render Header based on route */}
      {location.pathname !== '/' && location.pathname !== '/signup' && location.pathname !== '/forgot-password' && <Header />}
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/signup' element={<Signup />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        {/* Protect these routes */}
        <Route path='/home' element={<ProtectedRoute><Home /></ProtectedRoute>} />
        <Route path='/add-lead' element={<ProtectedRoute><LeadForm /></ProtectedRoute>} />
        <Route path='/users' element={<ProtectedRoute><UsersList /></ProtectedRoute>} />
        <Route path='/edit-user/:id' element={<ProtectedRoute><EditUser /></ProtectedRoute>} />
        <Route path='/add-user/' element={<ProtectedRoute><AddUser /></ProtectedRoute>} />
        <Route path='/index' element={<ProtectedRoute><LeadList /></ProtectedRoute>} />
        <Route path='/edit-lead/:id' element={<ProtectedRoute><EditLead /></ProtectedRoute>} />
        <Route path='/channels-list' element={<ProtectedRoute><ChannelsList /></ProtectedRoute>} />
        <Route path='/add-channel' element={<ProtectedRoute><AddChannel /></ProtectedRoute>} />
        <Route path='/edit-channel/:id' element={<ProtectedRoute><EditChannel /></ProtectedRoute>} />
        <Route path="/posts" element={<ProtectedRoute><Posts /></ProtectedRoute>} />
        <Route path="/add-post" element={<ProtectedRoute><AddPost /></ProtectedRoute>} />
        <Route path="/edit-post/:id" element={<ProtectedRoute><EditPost /></ProtectedRoute>} />
        <Route path="/inbox" element={<ProtectedRoute><ChatLog /></ProtectedRoute>} />
        <Route path="/change-password" element={<ProtectedRoute><ChangePassword /></ProtectedRoute>} />
        <Route path='/profile' element={<ProtectedRoute><ProfileList /></ProtectedRoute>} />
        <Route path='/add-profile' element={<ProtectedRoute><AddProfile /></ProtectedRoute>} />
        <Route path='/edit-profile/:id' element={<ProtectedRoute><EditProfile /></ProtectedRoute>} />
        <Route path='/chatuserchannels/add/facebook' element={<ProtectedRoute requiredRole="admin"><FacebookLogin /></ProtectedRoute>} />
        <Route path='/myprofile' element={<ProtectedRoute><MyProfile /></ProtectedRoute>} />
        <Route path='/update-profile/:id' element={<ProtectedRoute><UpdateProfile /></ProtectedRoute>} />
        <Route path="/chatuserchannels" element={<ProtectedRoute><ChatUserChannels /></ProtectedRoute>} />
        <Route path="/chatuserchannels/add" element={<ProtectedRoute><AddChatUserChannels /></ProtectedRoute>} />
        <Route path='/add-form' element={<ProtectedRoute><AddFormBuilder /></ProtectedRoute>} />
        <Route path='/forms-list' element={<ProtectedRoute><FormBuilder /></ProtectedRoute>} />
        <Route path='/edit-form/:id' element={<ProtectedRoute><EditForm /></ProtectedRoute>} />
        <Route path='/email-editor' element={<ProtectedRoute><EmailsEditor /></ProtectedRoute>} />
        <Route path='/add-template' element={<ProtectedRoute><AddEmailsEditor /></ProtectedRoute>} />
        <Route path='/edit-template/:id' element={<ProtectedRoute><EditEmailsEditor /></ProtectedRoute>} />
        <Route path='/campaigns' element={<ProtectedRoute><Campaigns /></ProtectedRoute>} />
      </Routes>
    </>
  );
}; 

export default App;
