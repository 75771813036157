import React, { useState, useEffect, useCallback, useRef } from "react";
import EmailEditor from "raven-react-email-editor";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import config from "../../config";

const EditTemplate = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [templateName, setTemplateName] = useState("");
  const [savedState, setSavedState] = useState({ state: { blocks: [] }, html: "" });
  const [isLoaded, setIsLoaded] = useState(false);
  const [user, setUser] = useState(null); // User state
  const editorRef = useRef(null);

  // Fetch user from sessionStorage
  useEffect(() => {
    const storedUser = sessionStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    } else {
      navigate("/login"); // Redirect if no user is stored
    }
  }, [navigate]);

  // Fetch template
  useEffect(() => {
    let isMounted = true; // Prevent state updates if unmounted
    const fetchTemplate = async () => {
      try {
        const response = await axios.get(
          `${config.apiHost}:${config.apiPort}/templates/${id}`
        );
        if (isMounted) {
          const { template_name, template_content, editor_state } = response.data;
          setTemplateName(template_name);
          setSavedState({
            state: editor_state ? JSON.parse(editor_state) : { blocks: [] },
            html: template_content || "",
          });
          setIsLoaded(true);
        }
      } catch (error) {
        if (isMounted) {
          console.error("Error fetching template:", error);
          alert("Failed to load the template. Please try again.");
        }
      }
    };
    fetchTemplate();
    return () => {
      isMounted = false; // Cleanup
    };
  }, [id]);

  const onEditorSave = useCallback(async () => {
    if (!isLoaded || !user || !templateName.trim()) {
      alert("Invalid input. Please ensure you are logged in and the template has a name.");
      return;
    }

    const { id: userId } = user;
    editorRef.current.fetchState(); // Fetch editor state
    console.log("Fetched State:", savedState);

    const updatedTemplateData = { 
      template_name: templateName,
      template_type: "email",
      user_id: userId,
      template_content: savedState.html,
      editor_state: JSON.stringify(savedState.state),
    };

    try {
      await axios.put(`${config.apiHost}:${config.apiPort}/templates/${id}`, updatedTemplateData);
      alert("Template updated successfully!");
      navigate("/email-editor");
      console.log("Updated Data Sent:", updatedTemplateData);

    } catch (error) {
      console.error("Error updating template:", error);
      alert("Failed to update the template. Please try again.");
    }
  }, [isLoaded, templateName, savedState, user, id, navigate]);

  if (!user) return <div>Loading...</div>;

  return (
    <div className="p-4">
      <div className="d-flex justify-content-between mb-3 align-items-center">
        <h2>Edit Email Template</h2>
        <div className="d-flex align-items-center">
          <input
            type="text"
            className="form-control me-2"
            placeholder="Template Name"
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
            style={{ maxWidth: "300px" }}
          />
          <button className="btn btn-primary text-nowrap" onClick={onEditorSave} disabled={!isLoaded}>
            Update Template
          </button>
        </div>
      </div>

      <div
        className="border rounded shadow-sm bg-white"
        style={{ height: "70vh", overflow: "hidden" }}
      >
        {isLoaded && (
          <EmailEditor
            state={savedState.state}
            onEditorLoad={() => setIsLoaded(true)}
            onFetched={(state, html) => setSavedState({ state, html })}
            ref={editorRef}
          />
        )}
      </div>
    </div>
  );
};

export default EditTemplate;
