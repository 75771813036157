import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaEdit, FaTrash } from 'react-icons/fa';
import { Link, useNavigate } from "react-router-dom";
import config from '../../config';
import Pagination from '../pagination';

const ProfileList = () => {
  const [profiles, setProfiles] = useState([]);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [profilesPerPage, setProfilesPerPage] = useState(5);
  const [totalProfiles, setTotalProfiles] = useState(0);

  useEffect(() => {
    axios.get(`${config.apiHost}:${config.apiPort}/profiles`)
      .then(response => {
        // Ensure the response data matches the expected format
        if (Array.isArray(response.data)) {
          setProfiles(response.data);
          setTotalProfiles(response.data.length);
        } else {
          console.log("Expected an array, but got:", response.data);
          setProfiles([]);  // Set empty array if it's not an array
        }
      })
      .catch(error => {
        console.error("Error fetching profiles:", error);
        setProfiles([]); // Fallback to an empty array if error occurs
      });
  }, []);

  // Get current profiles for the current page
  const indexOfLastProfile = currentPage * profilesPerPage;
  const indexOfFirstProfile = indexOfLastProfile - profilesPerPage;
  const currentProfiles = profiles.slice(indexOfFirstProfile, indexOfLastProfile);

  const handleEdit = (id) => {
    navigate(`/edit-profile/${id}`);
  };

  const handleDelete = (id) => {
    if(window.confirm("Are you sure you want to delete this profile?")){
    axios.delete(`${config.apiHost}:${config.apiPort}/profiles/${id}`)
      .then(response => {
        console.log('Profile deleted successfully:', response);
        // Remove the profile from state after successful deletion
        setProfiles(profiles.filter(profile => profile.id !== id));
      })
      .catch(error => {
        console.error('Error deleting profile:', error);
      });
    }
  };

  // Handle pagination change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  return (
    <div className="container mt-5">
      <div className='d-flex justify-content-between'>
        <h2>Profiles List</h2>
        <Link to="/add-profile" className="btn btn-outline-dark mb-3">Add Profile</Link>
      </div>
      <table className="table table-striped border">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Mobile</th>
            <th>Email</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentProfiles.length > 0 ? (
            profiles.map(profile => (
              <tr key={profile.id}>
                <td>{profile.id}</td>
                <td>{profile.name}</td>
                <td>{profile.mobile}</td> {/* Ensure mobile is fetched correctly */}
                <td>{profile.email}</td>
                <td>
                  <FaEdit 
                    onClick={() => handleEdit(profile.id)} 
                    style={{ cursor: 'pointer', marginRight: '10px' }} 
                  />
                  <FaTrash 
                    onClick={() => handleDelete(profile.id)} 
                    style={{ cursor: 'pointer' }} 
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" className="text-center">No profiles found</td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Pagination Component */}
      <Pagination
        totalItems={totalProfiles}
        itemsPerPage={profilesPerPage}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        setItemsPerPage={setProfilesPerPage}
      />

    </div>
  );
};

export default ProfileList;
