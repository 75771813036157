import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import config from '../../config';

const UpdateProfile = () => {
  const [user, setUser] = useState({
    first_name: '',
    last_name: '',
    mobile: '',
    email: ''
  });
  const { id } = useParams(); // Assuming user ID is passed in the URL
  const navigate = useNavigate();
  const [toast, setToast] = useState({ show: false, message: "", type: "" });

  useEffect(() => {
      axios.get(`${config.apiHost}:${config.apiPort}/user/${id}`)
        .then(response => {
          const fetchedUser = response.data;
          console.log(fetchedUser);
          setUser({
            first_name: fetchedUser.first_name || '',
            last_name: fetchedUser.last_name || '',
            mobile: fetchedUser.mobile || '',
            email: fetchedUser.email || ''
          });
        })
        .catch(error => {
          console.error('Error fetching user data:', error);
        });
  }, [id]);

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.put(`${config.apiHost}:${config.apiPort}/update-user/${id}`, user)
      .then(response => {
        setToast({
          show: true,
          message: "Profile updated successfully!",
          type: "success",
        });
        // Optionally update session storage
        sessionStorage.setItem('user', JSON.stringify(user));
        setTimeout(() => {
          setToast({ show: false });
          navigate('/myprofile');
        }, 3000);
      })
      .catch(error => {
        console.error('Error updating profile:', error);
        setToast({
          show: true,
          message: "Failed to update profile",
          type: "danger",
        });
        setTimeout(() => setToast({ show: false }), 3000);
      });
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center">Update Profile</h2>
      <form onSubmit={handleSubmit} className="mx-auto" style={{ maxWidth: '600px' }}>
        <div className="mb-3">
          <label htmlFor="first_name" className="form-label">First Name</label>
          <input 
            type="text" 
            className="form-control" 
            id="first_name" 
            name="first_name" 
            value={user.first_name} 
            onChange={handleChange} 
            required 
          />
        </div>
        <div className="mb-3">
          <label htmlFor="last_name" className="form-label">Last Name</label>
          <input 
            type="text" 
            className="form-control" 
            id="last_name" 
            name="last_name" 
            value={user.last_name} 
            onChange={handleChange} 
            required 
          />
        </div>
        <div className="mb-3">
          <label htmlFor="mobile" className="form-label">Mobile</label>
          <input 
            type="text" 
            className="form-control" 
            id="mobile" 
            name="mobile" 
            value={user.mobile} 
            onChange={handleChange} 
            required 
          />
        </div>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">Email</label>
          <input 
            type="email" 
            className="form-control" 
            id="email" 
            name="email" 
            value={user.email} 
            onChange={handleChange} 
            required 
          />
        </div>
        <button type="submit" className="btn btn-primary">Save Changes</button>
      </form>

      {/* Toast for feedback */}
      {toast.show && (
        <div
          className={`toast-container position-fixed start-50 translate-middle p-3`}
          style={{ zIndex: 5, top: 550 }}
        >
          <div
            className={`toast show bg-${toast.type} text-white`}
            role="alert"
          >
            <div className="toast-body">{toast.message}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UpdateProfile;
