import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import config from '../../config';

const AddChannel = () => {
  const [name, setName] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [toast, setToast] = useState({ show: false, message: '', type: '' });
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    // Trim input values to remove leading and trailing spaces
    const trimmedName = name.trim();
    const trimmedDisplayName = displayName.trim();

    // Check if the fields are empty after trimming
    if (!trimmedName || !trimmedDisplayName) {
      setToast({
        show: true,
        message: 'Fields cannot be empty or contain only spaces.',
        type: 'danger',
      });
      setTimeout(() => setToast({ show: false }), 3000);
      return;
    }

    // If validation passes, submit the data
    axios.post(`${config.apiHost}:${config.apiPort}/channels`, { name: trimmedName, display_name: trimmedDisplayName })
      .then(response => {
        console.log('Channel added successfully:', response);
        setToast({
          show: true,
          message: 'Channel submitted successfully!',
          type: 'success',
        });
        // Clear the form fields after successful submission
        setName('');
        setDisplayName('');
        setTimeout(() => {
          setToast({ show: false });
          navigate('/channels-list');
        }, 3000);
      })
      .catch(error => {
        console.error('Error adding channel:', error);
        setToast({
          show: true,
          message: 'Failed to submit channel',
          type: 'danger',
        });
        setTimeout(() => setToast({ show: false }), 3000);
      });
  };

  return (
      <div className='container-fluid pt-5'>
        <div className='row'>
          <div className='col-8 main-container'>
            <div className="container">
            <h2>Add Channel</h2>
            <form onSubmit={handleSubmit}>
            <div className="mb-3">
            <label className="form-label">Name</label>
            <input
              type="text"
              className="form-control"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            </div>
            <div className="mb-3">
            <label className="form-label">Display Name</label>
            <input
              type="text"
              className="form-control"
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
              required
            />
            </div>
            <button type="submit" className="btn btn-outline-dark">Add</button>
            </form>

            {toast.show && (
            <div className={`toast-container position-fixed start-50 translate-middle p-3`} style={{ zIndex: 5, top: 550 }}>
            <div
              className={`toast show bg-${toast.type} text-white`}
              role="alert"
            >
              <div className="toast-body">{toast.message}</div>
            </div>
            </div>
            )}
            </div>
          </div>
          <div className='col-4 extra-container'>

          </div>
        </div>
      </div>
  );
};

export default AddChannel;
