import React, { useEffect } from 'react';
import axios from 'axios';
import config from '../../config'; // Adjust the path based on your project structure


const Pagination = ({ totalItems, itemsPerPage, currentPage, onPageChange, setItemsPerPage }) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  useEffect(() => {
    // Fetch pagelimit from database
    axios.get(`${config.apiHost}:${config.apiPort}/appsettings/pagelimit`)
      .then(response => {
        const pagelimit = parseInt(response.data.pagelimit, 10);
        setItemsPerPage(pagelimit);
      })
      .catch(error => {
        console.error('Error fetching pagelimit:', error);
      });
  }, [setItemsPerPage]);

  // Hide pagination if total items are less than or equal to itemsPerPage
  if (totalItems <= itemsPerPage) {
    return null; // Do not render pagination
  }

  const handlePageChange = (pageNumber) => {
    onPageChange(pageNumber);
  };

  const renderPageNumbers = () => {
    let pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
          <button onClick={() => handlePageChange(i)} className="page-link">
            {i}
          </button>
        </li>
      );
    }
    return pages;
  };

  return (
    <nav aria-label="Page navigation example">
      <ul className="pagination justify-content-center">
        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <button onClick={() => handlePageChange(currentPage - 1)} className="page-link">
            Previous
          </button>
        </li>
        {renderPageNumbers()}
        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
          <button onClick={() => handlePageChange(currentPage + 1)} className="page-link">
            Next
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
